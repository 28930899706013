import { useEffect, useState } from 'react';
import { Layer } from 'woodpecker';

interface UseLimitLoadProps {
  data: { layers: Layer[] };
  layerLoading: boolean;
  disabled: boolean;
}

export const FEATURE_THRESHOLD = 1000;

export const useLimitLoad = ({ data, layerLoading, disabled }: UseLimitLoadProps) => {
  const [limitValidationProcessing, setLimitValidationProcessing] = useState(true);
  const [allowLoad, setAllowLoad] = useState(true);

  useEffect(() => {
    let count = 0;
    if (!limitValidationProcessing || disabled) {
      return;
    }
    if (data?.layers && !layerLoading) {
      data?.layers?.forEach(layer => {
        const layerFeatureLength = layer?.original_json?.features?.length ?? 0;
        count += layerFeatureLength;
      });
      setAllowLoad(count <= FEATURE_THRESHOLD);
      setLimitValidationProcessing(false);
    }
  }, [data?.layers, layerLoading, limitValidationProcessing, disabled]);

  return {
    allowLoad,
    limitValidationProcessing,
    setAllowLoad
  };
};
