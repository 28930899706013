import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  MEASUREMENT_TYPE_KEY,
  MAP_TYPE,
  Layer,
  CurrentJob,
  CurrentSheet,
  DIMENSION_TOOL_LAYER,
  HIGHLIGHT_TOOL_LAYER,
  COMMENT_LAYER_ID,
  ToolBarItemsTypes,
  AUTO_SCALE_TOOL_LAYER
} from 'woodpecker';
import { GEO_JSON } from 'macaw';
import { Feature, MapBrowserEvent, Overlay } from 'ol';
import { Pixel } from 'ol/pixel';
import _, { debounce } from 'lodash';
import VectorLayer from 'ol/layer/Vector';
import { showToast } from 'ui';
import { useShallow } from 'zustand/react/shallow';
import useMapStore, { mapStore } from '../store/mapStore';
import { styleFunctionDimenSionTool } from '../hooks/addition';
import { usePointCountUpdate } from '../hooks/tools/helpers';
import useMapHelpers, { returnGeojson } from '../hooks/helpers';
import ToolTip from '../components/ToolTip';
import ContextMenu from '../components/ContextMenu';
import useGeoJSON from '../hooks/geo-json.tsx';
import { executeAction, initOlMap, initTool, mapObj, undoRedoObj, undoRedoPush } from '../js-layer/mapLayer/mapInit';
import TOOL_MAP, { TOOL_TYPE } from '../js-layer/toolLayer/constants';
import ActionMenu from '../components/ActionMenu';
// default stylesheets provided by ol
import '../styles/ol.css';
// custom stylesheets
import '../styles/map.css';
import useToolTip from '../js-layer/hooks/useToolTip';
import ToolBarOverlay from '../components/ToolBarOverlay';
import CommentCard from '../components/CommentCard';
import { BOUNDARY, PARCEL } from '../hooks/tools/helpers/constants';
import RightClickMenu from '../components/RightClickMenu';
import TextBox from '../components/TextBox';
import useTextBoxStore, { OverlayDataType } from '../store/textBoxStore';
import { useTags } from '../store/tagsStore';
import { filterOutDeletedTags } from '../helpers/tagging';
import { reselectFeatures } from '../helpers/helpers';
import useCommentStore, { CommentType } from '../store/commentStore';
import { ClusterSuggestionPanel } from '../components/ClusterSuggestionPanel';
import { useLimitLoad } from '../js-layer/hooks/useLimitLoad';
import { HandToolControlPanel } from '../components/HandToolControlPanel';

const initialRightClickState: {
  show: boolean;
  x: number;
  y: number;
} = {
  show: false,
  x: 0,
  y: 0
};

interface MapsProps {
  layers: Map<string, any>; // Define the type for layers
  layerLoading?: boolean;
  dataLoading: boolean;
  data: { layers: Layer[] };
  selectedLayer: Layer;
  opacity: number;
  boundaryOpacity?: number;
  onTriggerSave: () => void;
  loaderComponent?: React.ReactNode;
  LeftFloatingComponent: React.ReactNode;
  RightFloatingComponent: React.ReactNode;
  setShowModalLineScale: (value: boolean) => void;
  setSelectedPanelLayer: (layer: Layer) => void;
  jobData: CurrentJob | CurrentSheet;
  mapType: number; // Assuming mapType is a string
  overlayLayers?: Map<string, any>;
  commentApis?: any;
  customToolBarItems?: ToolBarItemsTypes[];
  ContextMenuItems?: React.JSX.Element;
  handleMarkupAction?: (action: number, toolType: number, data: any) => void;
  textBoxData?: OverlayDataType[];
  commentsBPT?: CommentType[];
  CommentBox?: any;
  disabled: boolean;
  addTempLayer?: ((id: string, geojson: any) => void) | null;
  collapsed?: boolean;
}

const Maps = ({
  layers,
  layerLoading = false,
  dataLoading,
  data,
  selectedLayer,
  opacity,
  boundaryOpacity,
  onTriggerSave,
  setShowModalLineScale = () => {},
  setSelectedPanelLayer,
  jobData,
  mapType,
  overlayLayers,
  commentApis,
  customToolBarItems = [],
  ContextMenuItems,
  commentsBPT,
  CommentBox,
  handleMarkupAction,
  textBoxData,
  disabled,
  addTempLayer = null,
  collapsed = false
}: MapsProps) => {
  /*Local state */
  const toolTipRef = useRef<HTMLDivElement>();
  const toolBarOverlayRef = useRef<HTMLDivElement>();
  const [abstractValue, setAbstractValue] = useState<string>('');
  /*Context menu Toolbar state */
  const [rightClickMenuState, setRightClickMenuState] = useState<{
    x: number;
    y: number;
    show: boolean;
  }>(initialRightClickState);

  /*Gloabal store */
  const [
    map,
    setCurrentJob,
    setMap,
    setLayers,
    setUndoRedoIns,
    setMapObj,
    setCurrentLayers,
    allLayers,
    selectedTool,
    setSelectedTool,
    scale,
    dpi,
    setOverLay,
    overlay,
    setToolBarOverLay,
    selectedComment,
    setOnsaveData,
    setSelectedLayer,
    setSelectedFeatures,
    updateStateMapStore,
    setComments
  ] = useMapStore(
    useShallow((state: mapStore) => [
      state.map,
      state.setCurrentJob,
      state.populateMap,
      state.populateLayers,
      state.setUndoRedoIns,
      state.setMapObj,
      state.setCurrentLayers,
      state.layers,
      state.tool,
      state.setTool,
      state.worksheetParams?.scale,
      state.worksheetParams?.dpi,
      state.setOverLay,
      state.overlay,
      state.setToolBarOverLay,
      state.selectedComment,
      state.setOnsaveData,
      state.setSelectedLayer,
      state.setSelectedFeatures,
      state.updateState,
      state.setComments
    ])
  );
  const updateStateTextBox = useTextBoxStore(useShallow(state => state.updateState));
  const [taggingSelectIns, mutate] = useTags(useShallow(state => [state.selectIns, state.groupsMutation]));

  /*Helper functions */
  const { getVectorLayerById } = useMapHelpers();
  const { updateOnDebounce, refreshState } = usePointCountUpdate();

  const {
    updateState: updateStateComment,
    selectedCommentBPT,
    commentGeojson
  } = useCommentStore(state => ({
    updateState: state.updateState,
    selectedCommentBPT: state.selectedComment,
    commentGeojson: state.commentGeojson
  }));

  /*showToolTip function to show live measurements of features on hover */
  const { showToolTip } = useToolTip();

  /*Loads the geojson url for ai snapping (BPT feature)*/
  //@ts-ignore
  useGeoJSON(jobData?.geojson_url);
  const { allowLoad, limitValidationProcessing, setAllowLoad } = useLimitLoad({
    data,
    layerLoading: dataLoading,
    disabled: mapType !== MAP_TYPE.BLUEPRINT
  });
  /* function which is called whenever there is a change in any layer
  to save the latest changes on map
   */
  const saveData = useCallback(() => {
    if (onTriggerSave) onTriggerSave();
    updateOnDebounce();
  }, [onTriggerSave, updateOnDebounce]);

  /* function which is called whenever there is a change view on Map.
  This is responsible for changing size of text box overlay
   */
  const updateOverlays = useCallback(() => {
    if (!map) return;
    const overlays = map?.getOverlays().getArray() || [];
    overlays.forEach((overlay: any) => {
      const labelBoxData = overlay.get('labelBoxData');

      if (labelBoxData) {
        const originalWidth = labelBoxData.width;
        const originalHeight = labelBoxData.height;

        const resolution = map?.getView()?.getResolution() || 1;
        const scaleFactor = 1 / resolution;

        const adjustedWidth = originalWidth * scaleFactor;
        const adjustedHeight = originalHeight * scaleFactor;

        const divElement = overlay.getElement();
        divElement.style.width = `${adjustedWidth}px`;
        divElement.style.height = `${adjustedHeight}px`;
      }
    });
  }, [map, map?.getOverlays(), map?.getView()]);

  useEffect(() => {
    setOnsaveData(saveData);
  }, [saveData, setOnsaveData]);

  /**
   * Initializes the OpenLayers map, sets the map instance in the component's state,
   * and loads the appropriate layer based on the mapType and jobData.
   * @param {number} mapType - The type of map (e.g., MAP_TYPE.AERIAL or MAP_TYPE.BPT).
   * @param {CurrentJob|CurrentSheet} jobData - The data for the current job/sheet.
   * @returns {void}
   */
  useEffect(() => {
    // Initialize the OpenLayers map
    initOlMap(mapType);
    // Set the map instance in the component's state
    setMap(mapObj.map);

    // If both mapObj and jobData are available, set the current job data
    // and load the appropriate layer based on the mapType
    if (mapObj && jobData) {
      setCurrentJob(jobData);
      mapType === MAP_TYPE.AERIAL
        ? setTimeout(() => mapObj.loadAerialLayer(jobData as CurrentJob), 0)
        : setTimeout(() => mapObj.loadBPTlayer(jobData as CurrentSheet), 0);
    }

    // Cleanup function: reset map data and destroy the map
    return () => {
      setMap(null);
      mapObj.destroyMap();
    };
  }, [jobData]);

  /*Updates the styling of line_geojson whenever scale/dpi changes */
  useEffect(() => {
    const layer = getVectorLayerById(DIMENSION_TOOL_LAYER);
    if (layer) layer.setStyle((feature: Feature) => styleFunctionDimenSionTool(feature, scale, dpi));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale, dpi]);

  /**
   * Initializes an overlay for displaying tooltips on the map.
   * @param {React.RefObject<HTMLElement>} toolTipRef - The reference to the tooltip element.
   * @returns {void}
   */
  useEffect(() => {
    const overlay = new Overlay({
      element: toolTipRef?.current,
      positioning: 'bottom-left',
      offset: [5, -5]
    });

    setOverLay(overlay);
  }, [setOverLay]);

  useEffect(() => {
    const overlay = new Overlay({
      element: toolBarOverlayRef?.current,
      positioning: 'top-center'
    });

    setToolBarOverLay(overlay);
  }, [setToolBarOverLay]);

  /**
   * Sets up layers in the map based on the data received and triggers an undo/redo push after a delay.
   * This effect runs when the map and layer loading state are both ready.
   */

  const reloadVectors = (data: any) => {
    const featPayload: object[] = [];
    const deletedPayload: object[] = [];
    const layerPresent = new Set();
    data?.layers?.forEach((layer: { id: string }) => {
      layerPresent.add(layer?.id);
    });

    const allLayers = mapObj?.map?.getAllLayers();
    allLayers?.forEach(layer => {
      if (layer?.get('is_annotation_layer')) {
        const layerID = layer?.get('id');
        if (!layerPresent?.has(layerID)) mapObj?.map?.removeLayer(layer);
      }
    });

    setTimeout(() => {
      //@ts-expect-error
      mapObj.addVectorLayer(jobData?.boundary_layer_json, BOUNDARY, opacity);
      data?.layers?.forEach((layer: Layer) => {
        const default_tags = layer?.default_tags || {};
        const {
          hasDeleted,
          tags_info: filtered_default_tags,
          deletedtagTypeIds
        } = filterOutDeletedTags(default_tags, true);
        if (hasDeleted) {
          featPayload.push({
            feature_id: layer?.id,
            default_tags: filtered_default_tags
          });
          deletedPayload.push({
            layerId: layer?.id,
            tagTypeIds: deletedtagTypeIds
          });
        }
        mapObj.addVectorLayer(
          layer.original_json,
          layer.id as string,
          opacity,
          filtered_default_tags,
          true,
          allowLoad,
          layer?.output_id
        );
      });

      if (featPayload?.length) {
        mutate({
          forceMutation: true,
          data: featPayload,
          deletedData: deletedPayload
        });
      }
      if ([TOOL_TYPE.TAG, TOOL_TYPE.GROUP_TAG].includes(selectedTool?.tool_id)) {
        reselectFeatures(taggingSelectIns);
      }
      if (selectedTool?.tool_id === TOOL_TYPE.SELECT) {
        setSelectedFeatures([]);
      }
    }, 10);

    setTimeout(() => undoRedoPush(), 10);
  };

  useEffect(() => {
    if (mapType === MAP_TYPE.AERIAL) {
      if (map) {
        // this can take a lot of time. Hence, this should be excecute last in call stack
        setTimeout(() => {
          reloadVectors(data);
          setLayers(new Map(layers));
          setCurrentLayers(data.layers);
        }, 1000);
      }
    } else if (map && !layerLoading && !limitValidationProcessing) {
      // this can take a lot of time. Hence, this should be excecute last in call stack
      setTimeout(() => {
        reloadVectors(data);
        setLayers(new Map(layers));
        setCurrentLayers(data?.layers);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.layers, layers, map, limitValidationProcessing]);

  /*Changes opacity of mounted vector layers */
  useEffect(() => {
    if (map) {
      map.getAllLayers().forEach((layer: any) => {
        if (layer instanceof VectorLayer) {
          if (layer.get('id') === BOUNDARY || layer.get('id') === PARCEL) return;
          mapObj.changeOpacity(layer.get('id'), opacity);
        }
      });
    }
  }, [opacity, map]);

  useEffect(() => {
    if (map && boundaryOpacity !== undefined) {
      mapObj.changeOpacity(PARCEL, boundaryOpacity);
    }
  }, [boundaryOpacity, map]);

  /**
   * Adds overlay layers like line_geojson which are not part of the actual layers.
   * This effect runs when the `overlayLayers` or `map` dependencies change.
   * It iterates over the `overlayLayers` map and adds each layer to the map using `addVectorLayer` method from `mapObj`.
   * @param {Map} map - The OpenLayers map object.
   * @param {Map<string, any>} overlayLayers - A map containing overlay layers to be added to the map.
   */
  useEffect(() => {
    if (map) {
      /*its put in setTimeout to send this in macrotask queue so that before mounting of layers 
      map and the global state gets updated */
      setTimeout(() => {
        overlayLayers?.forEach((value: any) => {
          if (value.layer_type !== AUTO_SCALE_TOOL_LAYER)
            mapObj.addVectorLayer(value.original_json, value.layer_type, 30, {}, false, true);
        });
      }, 10);
    }
  }, [overlayLayers, map]);

  // keeping seperate for autoScale annotation because overlayLayers data are staled once something annotated on map
  useEffect(() => {
    if (map) {
      /*its put in setTimeout to send this in macrotask queue so that before mounting of layers 
      map and the global state gets updated */
      setTimeout(() => {
        overlayLayers?.forEach((value: any) => {
          if (value.layer_type === AUTO_SCALE_TOOL_LAYER)
            mapObj.addVectorLayer(value.original_json, value.layer_type, 30, {}, false, true);
        });
      }, 10);
    }
  }, [overlayLayers?.get(AUTO_SCALE_TOOL_LAYER), map]);

  useEffect(() => {
    setUndoRedoIns(undoRedoObj);
  }, [undoRedoObj]);

  useEffect(() => {
    setMapObj(mapObj);
  }, [mapObj]);

  /**
   * This function is called when we double click on the map.
   * It gets the features on the clicked pixel and selects the feature,also sets the selectedLayer in layer panel to the layer of clicked feature.
   * @param {MapBrowserEvent} event - The double click event on the map.
   */
  const dblclickSelectFeature = useCallback(
    (event: MapBrowserEvent<UIEvent>) => {
      const pixel = map?.getEventPixel(event.originalEvent);
      const features = map?.getFeaturesAtPixel(pixel as Pixel);
      if (!features?.length) return;

      if (
        features[0].get('vector_layer_id') === DIMENSION_TOOL_LAYER ||
        features[0].get('vector_layer_id') === HIGHLIGHT_TOOL_LAYER ||
        features[0].get('vector_layer_id') === PARCEL ||
        !features[0].get('vector_layer_id')
      )
        return;

      setSelectedPanelLayer(features[0].get('vector_layer_id'));
      //layer changes sets the tool to default so adding change tool on dblclick to callback queue
      setTimeout(() => {
        setSelectedTool({ ...selectedTool, tool_id: TOOL_TYPE.SELECT });
        setSelectedFeatures([features[0]] as any);
      });
    },
    [map, selectedTool, setSelectedFeatures, setSelectedPanelLayer, setSelectedTool]
  );

  /*Side effect for attaching dblclick event listener to map */
  useEffect(() => {
    if (map) {
      if ([TOOL_TYPE.PAN, TOOL_TYPE.SELECT].includes(selectedTool.tool_id)) {
        map.on('dblclick', dblclickSelectFeature);
      }
    }
    return () => {
      if (map) map.un('dblclick', dblclickSelectFeature);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, selectedTool]);

  /**
   * Sets up event listeners for displaying a tooltip based on the selected layer and tool.
   * This effect runs when a selected layer ID exists.
   * If the selected tool is the pan tool, the tooltip is shown on pointer move over the map.
   * If the selected tool is not the pan tool, the tooltip is hidden, and the pointermove event listener is removed.
   */
  useEffect(() => {
    if (!selectedLayer?.id) return;

    if (map) {
      if ([TOOL_TYPE.PAN, TOOL_TYPE.TAG, TOOL_TYPE.GROUP_TAG].includes(selectedTool.tool_id)) {
        map.on('pointermove', showToolTip);
      } else {
        map.un('pointermove', showToolTip);
        overlay!.getElement()!.style.display = 'none';
      }
    }

    return () => {
      if (map) {
        map.un('pointermove', showToolTip);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer, map, allLayers, selectedTool.tool_id]);

  /*Updating live measurements value */
  useEffect(() => {
    updateOnDebounce();
    undoRedoPush();
    refreshState();
  }, [allLayers, updateOnDebounce]);

  /*Comment overlay show */
  useEffect(() => {
    if (selectedComment?.id) {
      const feature = GEO_JSON.readFeature(selectedComment?.geojson?.features[0]);
      const source = mapObj.getLayerById(COMMENT_LAYER_ID).getSource();
      source.clear();
      source.addFeature(feature);

      TOOL_MAP[TOOL_TYPE.ADD_COMMENT].getObject().showCommentCard(feature);
    }
  }, [selectedComment]);

  useEffect(() => {
    if (selectedTool?.tool_id === TOOL_TYPE?.COMMENT_BPT) {
      const commentObj = TOOL_MAP[TOOL_TYPE?.COMMENT_BPT]?.getObject();
      if (selectedCommentBPT?.id) {
        commentObj.showCommentCard(selectedCommentBPT, selectedCommentBPT?.triggeredFromRightPanel);
      } else commentObj.showCommentCard(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommentBPT]);

  /*Initializes tool/action whenever selectedLayer or selectedTool changes */
  useEffect(() => {
    if (selectedTool.is_action) {
      executeAction(selectedTool.tool_id);
    } else
      initTool(
        selectedTool.tool_id,
        selectedLayer?.id as string,
        selectedTool?.snapping_mode,
        selectedTool?.add_comment,
        selectedTool?.show_vertex,
        selectedTool?.edge_snapping,
        selectedTool?.feature_tracing,
        selectedTool?.snap_tolerance,
        addTempLayer
      );
  }, [selectedTool, selectedLayer]);

  useEffect(() => {
    if (MAP_TYPE.BLUEPRINT === mapType) updateStateMapStore({ setShowModalLineScale });
  }, [setShowModalLineScale]);

  /*Set comments for aerial case */
  useEffect(() => {
    //@ts-ignore
    if (mapType === MAP_TYPE.AERIAL && jobData?.comments?.length) {
      //@ts-ignore

      setComments(jobData?.comments);
    }
    //@ts-ignore
  }, [jobData?.comments]);

  /*Sets the selectedVectorLayer in mapstore */
  useEffect(() => {
    if (selectedLayer?.id) {
      /*After all the layers are mounted on map then we setSelectedLayer */
      updateStateMapStore({ selectedLayerID: selectedLayer?.id || '' });
      setTimeout(() => {
        setSelectedLayer(mapObj.getLayerById(selectedLayer?.id as string));
      }, 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer]);

  /* This sets the comments of BPT app into store */
  useEffect(() => {
    if (mapType === MAP_TYPE.BLUEPRINT && !!commentsBPT) {
      updateStateComment(() => ({ commentList: commentsBPT }));
    }
    if (selectedTool?.tool_id === TOOL_TYPE?.COMMENT_BPT) {
      const commentObj = TOOL_MAP[TOOL_TYPE?.COMMENT_BPT]?.getObject();
      commentObj.init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsBPT, mapType]);

  /**zooms in/out depending on the value of zoomDirection provided
   * @param {number} zoomDirection - TValue 1 for zoom in and -1 for zoom out.
   **/
  const zoom = (zoomDirection: number) => {
    const view = map?.getView();
    const zoom = view?.getZoom() as number;
    view?.animate({
      zoom: zoom + zoomDirection,
      duration: 500
    });
  };

  useEffect(() => {
    map?.getView().on('change:resolution', debounce(updateOverlays, 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map?.getView(), updateOverlays]);

  // useEffect(() => {
  //   const handlePropertyChange = (e: any) => {
  //     if (e.key === "center") {
  //       const view = map?.getView();
  //       if (!view) return;
  //       const extent = view.calculateExtent(map?.getSize());
  //       const layers = map?.getLayers().getArray();
  //       layers?.forEach((layer) => {
  //         if (layer.get("id") === BASE_LAYER) return;
  //         // @ts-ignore
  //         const source = layer?.getSource();
  //         if (source && source.getFeatures) {
  //           const features = source.getFeatures();
  //           const someFeaturesInView = features.some((feature: any) => {
  //             const geometry = feature.getGeometry();
  //             return geometry && geometry.intersectsExtent(extent);
  //           });
  //           // Hide the layer if all features are not in view
  //           layer.setVisible(someFeaturesInView);
  //         }
  //       });
  //     }
  //   };

  //   const view = map?.getView();
  //   if (view) {
  //     view.on("propertychange", handlePropertyChange);
  //   }
  //   return () => {
  //     if (view) {
  //       view.un("propertychange", handlePropertyChange);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [map?.getView()]);
  /*
    This is action handle function of Markup tool to save data on BE
    This is stored in Store os that is can be use internally anywhere 
  */
  useEffect(() => {
    if (handleMarkupAction) updateStateTextBox({ handleMarkupAction });
  }, [handleMarkupAction, updateStateTextBox]);

  /*
    this useEffect this used to mount textBox on map.
    This also update data in textBoxStore every time changes are made to text box.
  */
  useEffect(() => {
    const textBoxObj = TOOL_MAP[TOOL_TYPE.TEXT_BOX]?.getObject();
    updateStateTextBox({
      textBoxOverlayData: [...(textBoxData || [])],
      textBoxObj
    });
    if (selectedTool?.tool_id !== TOOL_TYPE.TEXT_BOX) {
      const overlays = [...(mapObj.map?.getOverlays()?.getArray() || [])];
      if (overlays.length) {
        overlays.forEach(overlay => {
          if (overlay.get('labelBoxData')) {
            mapObj.map?.removeOverlay(overlay);
          }
        });
      }

      textBoxObj?.loadLabelBoxes(textBoxData || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textBoxData]);

  /**
   * Handles the cancellation of the context menu.
   * Clears the abstract value, hides the context menu element, and clears the vector layer with the id 'abstract-feature'.
   */
  const onContextCancel = useCallback(() => {
    const contextMenuElement = document.getElementById('context-menu') as any;
    setAbstractValue('');
    contextMenuElement.style.display = 'none';
    const vectorLayer = getVectorLayerById('abstract-feature')?.getSource();
    if (vectorLayer) vectorLayer.clear();
  }, [getVectorLayerById]);

  /**
   * Handles the saving of the context menu changes.
   * Modifies the properties of the last feature in the 'abstract-feature' vector layer based on the abstract value.
   * Clears the 'abstract-feature' vector layer, adds the modified features to the selected layer's vector layer, and updates the map.
   * Clears the abstract value, hides the context menu element, and pushes the changes to the undo/redo stack.
   */
  const onContextSave = useCallback(() => {
    if (abstractValue == null || abstractValue == '' || abstractValue == '0') {
      showToast("Abstract Feature value can't be empty or Zero", 'error');
      return;
    }
    const contextMenuElement = document.getElementById('context-menu') as any;
    const projection = map?.getView().getProjection();
    const GeoJsonOfSelectedLayer = returnGeojson(map, projection, selectedLayer?.id as string);
    const GeoJson = returnGeojson(map, projection, 'abstract-feature');
    const { features: featuresOfSelectedLayers } = GeoJsonOfSelectedLayer;
    const { features } = GeoJson;
    if (!features.length) return;

    const lastIndex = features?.length - 1;
    features[lastIndex].properties = {
      id: featuresOfSelectedLayers?.length + 1,
      [MEASUREMENT_TYPE_KEY[selectedLayer?.measurement_type ?? 1]]: abstractValue ? +abstractValue : 0
    };

    const vectorLayer = getVectorLayerById('abstract-feature').getSource();
    vectorLayer.clear();

    const newfeatures = GEO_JSON.readFeatures(GeoJson, projection as any);

    const finalFeature: Feature[] = [];
    newfeatures?.forEach((feature: Feature) => {
      const property = feature.getProperties();
      if (property && property?.id) {
        finalFeature.push(feature);
      }
    });

    getVectorLayerById(selectedLayer?.id as string)
      .getSource()
      .addFeatures(finalFeature);
    setAbstractValue('');
    contextMenuElement.style.display = 'none';
    undoRedoPush();
  }, [abstractValue, getVectorLayerById, map, selectedLayer?.id, selectedLayer?.measurement_type]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = +parseFloat(e.target.value);
    const maxVal = 999999999999999;
    if (!Number.isNaN(newValue) && newValue >= 0) {
      if (newValue > maxVal) {
        //showing toast just once when the entered value exceeds max value
        if (Number(abstractValue) < maxVal) showToast('Value exceeded the maximum limit', 'error');
        newValue = maxVal;
      }
      setAbstractValue(`${newValue}`);
    } else {
      setAbstractValue('');
    }
  };

  /**Function called on right click event on map opening 
  the custom toolbar for selected features only 
    * @param {any} event - right click event.
  **/
  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    const { pageX, pageY } = event;
    const mapContainer = document.getElementById('map-container');
    const { left, top } = mapContainer?.getBoundingClientRect() || { left: 0, top: 0 };
    const [x, y] = [pageX - left, pageY - top];

    if (mapType === MAP_TYPE.BLUEPRINT) {
      const features = map?.getFeaturesAtPixel([x, y]);
      const properties = features?.map(feature => feature.getProperties());
      updateStateMapStore({ featurePropsAtContext: properties });
    }

    setRightClickMenuState({ x, y, show: true });
  };

  /**
   * Closes the context menu toolbar that is opened on right click.
   * @param {any} event -  click event.
   */
  const closeContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setRightClickMenuState({ ...initialRightClickState });
    if (mapType === MAP_TYPE.BLUEPRINT) {
      updateStateMapStore({ featurePropsAtContext: null });
    }
  };

  return (
    <>
      <div id='map-container' className='container' onContextMenu={handleContextMenu} onClick={closeContextMenu}>
        <ToolTip toolTipRef={toolTipRef} selectedLayer={selectedLayer} />
        <TextBox />
        <ContextMenu
          selectedLayer={selectedLayer}
          abstractValue={abstractValue}
          handleInputChange={handleInputChange}
          onContextCancel={onContextCancel}
          onContextSave={onContextSave}
        />
        <ToolBarOverlay
          toolBarOverlayRef={toolBarOverlayRef}
          customToolBarItems={customToolBarItems}
          disabled={disabled}
        />
        <CommentCard commentApis={commentApis} />
        <HandToolControlPanel collapsed={collapsed} />
        {mapType === MAP_TYPE.BLUEPRINT && CommentBox ? (
          <div id='comment-box'>
            <CommentBox comment={selectedCommentBPT} geojson={commentGeojson} />
          </div>
        ) : null}
      </div>
      {selectedTool.tool_id === TOOL_TYPE.SELECT && rightClickMenuState.show && (
        <ActionMenu
          top={rightClickMenuState.y}
          left={rightClickMenuState.x}
          onClose={() => setRightClickMenuState(initialRightClickState)}
          setSelectedTool={setSelectedTool}
        />
      )}
      {selectedTool.tool_id !== TOOL_TYPE.SELECT && ContextMenuItems && (
        <RightClickMenu
          display={rightClickMenuState.show ? 'block' : 'none'}
          top={rightClickMenuState.y}
          left={rightClickMenuState.x}
          onClose={() => setRightClickMenuState(initialRightClickState)}
          Component={ContextMenuItems}
        />
      )}
      {mapType === MAP_TYPE.BLUEPRINT && (
        <ClusterSuggestionPanel
          allowLoad={allowLoad}
          limitValidationProcessing={limitValidationProcessing}
          setAllowLoad={setAllowLoad}
        />
      )}
    </>
  );
};

export default Maps;
