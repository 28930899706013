import { useEffect, useRef } from 'react';
import { ContextMenu } from './style';

const RightClickMenu = ({
  top,
  left,
  onClose,
  Component,
  display
}: {
  top: number;
  left: number;
  onClose: () => void;
  Component: JSX.Element;
  display: string;
}) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (e: Event) => {
      const el = contextMenuRef?.current;
      if (!el || el.contains(e?.target as Node) || null) return;
      onClose();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('visibilitychange', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('visibilitychange', listener);
    };
  }, [contextMenuRef, onClose]);

  return (
    <>
      <ContextMenu top={top} left={left} ref={contextMenuRef} display={display}>
        <span onClick={() => onClose()}>{Component}</span>
      </ContextMenu>
    </>
  );
};

export default RightClickMenu;
