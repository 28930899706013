import React, { useCallback, useMemo } from 'react';
import { Tooltip } from 'ui';
import { TOOLTIP_BAR_ITEMS, ToolBarItemsTypes } from 'woodpecker';
import { iconButton } from '../styles';
import useMapStore from '../../../store/mapStore';
import { executeAction } from '../../../js-layer/mapLayer/mapInit';
import { DEFAULT_TOOL } from 'woodpecker';

const ToolList = ({ featuresMap }: { featuresMap: Map<number, string[]> }) => {
  const { setSelectedTool, selectedTool } = useMapStore(state => ({
    setSelectedTool: state.setTool,
    selectedTool: state?.tool
  }));
  const TOOLS: ToolBarItemsTypes[] = useMemo(
    () =>
      TOOLTIP_BAR_ITEMS.filter(item => {
        let available: boolean = false;
        for (let index = 0; index < item?.geometry_available?.length; index++) {
          available = available || featuresMap?.has(item.geometry_available[index]);
        }
        if (
          !available ||
          (!item.multi && featuresMap.size > 1)
          //    ||
          //   (isExcluded && !item.excludedItems)
        )
          return false;
        if (
          (item.single && featuresMap.size > 1) ||
          (item.single && item.single && featuresMap.entries().next().value[1].length > 1)
        ) {
          return false;
        }
        return true;
      }),
    [featuresMap]
  );

  const onHandleClick = useCallback(
    (tool: ToolBarItemsTypes) => {
      if (tool.is_action) {
        executeAction(tool.tool_id);
        setSelectedTool({
          ...selectedTool,
          ...DEFAULT_TOOL,
          vector_layer_id: featuresMap.entries().next().value[1][0],
          clickId: Math.random().toString(36).substring(7)
        });
      } else
        setSelectedTool({
          ...selectedTool,
          title: tool?.title,
          tool_id: tool?.tool_id,
          is_action: tool?.is_action as boolean,
          lastTool: { ...selectedTool },
          vector_layer_id: featuresMap.entries().next().value[1][0],
          clickId: Math.random().toString(36).substring(7)
        });
    },
    [featuresMap, selectedTool, setSelectedTool]
  );

  return (
    <>
      {TOOLS?.map(tool => (
        <Tooltip key={tool.tool_id} title={tool.title}>
          <div className={iconButton} onClick={() => onHandleClick(tool)}>
            {tool.icon ? tool.icon(false) : ''}
          </div>
        </Tooltip>
      ))}
    </>
  );
};

export default ToolList;
