import PencilIcon from 'ui/src/Assets/PencilIcon';
import { SquareLineIcon } from 'ui/src/Assets/SquareLineIcon';
import { CircleLineIcon } from 'ui/src/Assets/CircleLineIcon';
import { CloudLineIcon } from 'ui/src/Assets/CloudLineIcon';
import { ThicknessIcon } from 'ui/src/Assets/ThicknessIcon';
import { ColorPicker } from './ColorPicker';
import { container, input, inputContainer, line, toolContainer, typeContainer } from './style';
import useHandDrawStore, { HandDrawingToolTypeEnum } from '../../store/handDrawStore';
import React from 'react';
import TOOL_MAP, { TOOL_TYPE } from '../../js-layer/toolLayer/constants';

type TypeContainerProps = {
  Icon: React.FunctionComponent<any>;
  isSelected: boolean;
  onClick: () => void;
};
const TypeContainer = ({ Icon, isSelected, onClick }: TypeContainerProps) => (
  <span
    className={typeContainer}
    onClick={onClick}
    style={{
      background: isSelected ? 'var(--color-primary-5, #edeffd)' : 'var(--color-background-white, #fff)'
    }}>
    <Icon fill={isSelected ? 'var(--color-primary-50, #4361ee)' : '#4d4d4d'} />
  </span>
);

export const HandToolControlPanel = ({ collapsed }: { collapsed?: boolean }) => {
  const { updateState, type, width, color } = useHandDrawStore();

  const toolInstance = TOOL_MAP[TOOL_TYPE.HAND_DRAWING_TOOL]?.toolObj;

  const reInitializeTool = () => {
    if (toolInstance?.init) toolInstance?.init();
  };

  const handleChange = (key: string, value: string | number | HandDrawingToolTypeEnum) => {
    updateState({ [key]: value });
    reInitializeTool();
  };

  const handleColorChange = (newHexColor: string) => handleChange('color', newHexColor);

  const handleTypeChange = (newType: HandDrawingToolTypeEnum) => handleChange('type', newType);

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => handleChange('width', +e.target.value);

  const isDrawSelected = type === HandDrawingToolTypeEnum.draw;
  const isBoxSelected = type === HandDrawingToolTypeEnum.box;
  const isCircleSelected = type === HandDrawingToolTypeEnum.circle;
  const isCloudSelected = type === HandDrawingToolTypeEnum.cloud;

  return (
    <div className={container} id='handtool-control-panel' style={{ right: `calc(${collapsed ? 3 : 20}rem + 10px)` }}>
      <ColorPicker color={color} onColorChange={handleColorChange} />
      <span className={line} />
      <div className={toolContainer}>
        <TypeContainer
          Icon={PencilIcon}
          onClick={handleTypeChange.bind(null, HandDrawingToolTypeEnum.draw)}
          isSelected={isDrawSelected}
        />
        <TypeContainer
          Icon={SquareLineIcon}
          onClick={handleTypeChange.bind(null, HandDrawingToolTypeEnum.box)}
          isSelected={isBoxSelected}
        />
        <TypeContainer
          Icon={CircleLineIcon}
          onClick={handleTypeChange.bind(null, HandDrawingToolTypeEnum.circle)}
          isSelected={isCircleSelected}
        />
        <TypeContainer
          Icon={CloudLineIcon}
          onClick={handleTypeChange.bind(null, HandDrawingToolTypeEnum.cloud)}
          isSelected={isCloudSelected}
        />
      </div>
      <span className={line} />
      <div className={inputContainer}>
        <ThicknessIcon />
        <input className={input} value={width} type='number' onChange={handleWidthChange} min='1' />
      </div>
    </div>
  );
};
