import React from "react";

export const CloudLineIcon = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#4D4D4D"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.91658 5.00016C4.92504 5.00016 2.49992 7.42529 2.49992 10.4168C2.49992 13.4084 4.92504 15.8335 7.91658 15.8335H13.7499C15.821 15.8335 17.4999 14.1546 17.4999 12.0835C17.4999 10.0124 15.821 8.3335 13.7499 8.3335C13.4763 8.3335 13.2096 8.36283 12.9527 8.41841C12.1574 6.41595 10.2023 5.00016 7.91658 5.00016ZM13.7499 17.5002H7.91658C4.00457 17.5002 0.833252 14.3288 0.833252 10.4168C0.833252 6.50481 4.00457 3.3335 7.91658 3.3335C10.4527 3.3335 12.6775 4.66632 13.9288 6.66973C16.8375 6.76409 19.1666 9.15183 19.1666 12.0835C19.1666 15.0751 16.7415 17.5002 13.7499 17.5002Z"
        fill="inherit"
      />
    </svg>
  );
};
