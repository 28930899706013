import { MapBrowserEvent } from 'ol';
import React, { useCallback } from 'react';
import { GEOMETRY_TYPES, MAP_TYPE, getHoverProperties } from 'woodpecker';
import { SimpleGeometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import useMapHelpers, { addOverlay } from '../../hooks/helpers';
import useMapStore from '../../store/mapStore';
import { isOutOfExtent } from '../../helpers/helpers';
import { mapObj } from '../mapLayer/mapInit';
import { generateAerialMeasurements } from '../../hooks/tools/helpers/measurements';
import { filterOutDeletedTags } from '../../helpers/tagging';

const useToolTip = () => {
  const { map, overlay, allLayers, setToolTipData } = useMapStore((state: any) => ({
    map: state.map,
    overlay: state.overlay,
    allLayers: state.layers,
    setToolTipData: state.setToolTipData
  }));

  const { getVectorLayerById } = useMapHelpers();

  const showToolTip = useCallback(
    (event: MapBrowserEvent<UIEvent>) => {
      const pixel = map.getEventPixel(event.originalEvent);
      const features = map.getFeaturesAtPixel(pixel);

      const element = overlay.getElement() as any;

      if (!element) return;
      if (isOutOfExtent(event, map, false)) {
        element.style.display = 'none';
        return;
      }

      if (features.length > 0) {
        addOverlay(map, element, event.coordinate, overlay);

        const data: any[] = [];
        features.forEach((feature: any) => {
          const properties = feature.getProperties();
          const layerId = properties.vector_layer_id;
          if (!layerId || !allLayers?.get(layerId)) return;

          const layer = getVectorLayerById(layerId);

          if (!layer) return;

          const tags_info = filterOutDeletedTags(properties?.tags_info || {})?.tags_info || {};

          const default_tags = filterOutDeletedTags(layer?.get('default_tags') || {})?.tags_info || {};

          const { layer_type, geometry_type, color } = allLayers?.get(layerId);

          const entry = {
            id: properties.id,
            name: layer_type,
            properties:
              mapObj?.map_type === MAP_TYPE.AERIAL
                ? generateAerialMeasurements(feature)
                : getHoverProperties(geometry_type, properties),
            geometry_type,
            color,
            tags_info,
            default_tags
          };

          data.push(entry);
        });

        setToolTipData(data);
      } else {
        element.style.display = 'none';
      }
    },
    [allLayers, getVectorLayerById, map, overlay, setToolTipData]
  );

  const showToolTipOnFeature = useCallback(
    (feature_id: string, vector_layer_id: string, hideToolTip: boolean = false) => {
      const source = mapObj?.getLayerById(vector_layer_id)?.getSource() as VectorSource;
      const feature = source?.getFeatureById(feature_id);
      const element = overlay.getElement();
      const geometry = feature?.getGeometry() as SimpleGeometry;
      const coordinates = geometry?.getCoordinates();
      const properties = feature?.getProperties();

      if (hideToolTip || !coordinates || coordinates?.length === 0) {
        element.style.display = 'none';
        return;
      }

      let coords = coordinates;
      if (geometry.getType() === GEOMETRY_TYPES.LINESTRING) {
        coords = coordinates[0];
      }
      if (geometry.getType() === GEOMETRY_TYPES.POLYGON) {
        coords = coordinates[0][0];
      }
      addOverlay(map, element, coords, overlay);

      const { layer_type, geometry_type, color } = allLayers?.get(properties?.vector_layer_id);
      setToolTipData([
        {
          id: properties?.id,
          name: layer_type,
          color,
          geometry_type,
          properties: getHoverProperties(geometry_type, properties)
        }
      ]);
    },
    [allLayers, map, overlay, setToolTipData]
  );

  return { showToolTip, showToolTipOnFeature };
};

export default useToolTip;
