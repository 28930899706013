import { LABEL_ACTIONS, MARKUP_TYPE } from 'woodpecker';
import { create } from 'zustand';

export type OverlayDataType = {
  text: string;
  text_color: string;
  text_size: number;
  box_color: string;
  id?: string;
  width: number;
  height: number;
  action: number;
  box_image?: object | string;
  box_lonlat?: number[];
  extent?: any;
  box_coords?: any;
  lon?: any;
  lat?: any;
  excluded?: boolean;
  external: boolean;
  date: string;
  properties: {
    tool_id: string;
    external: boolean;
  };
};

interface StoreType {
  textBoxOverlayData: OverlayDataType[];
  text: string;
  color: string;
  fontSize: number;
  date: string;
  textBoxObj: any;
  handleMarkupAction: ((action: number, toolType: number, data: any) => void) | null;
  box_color: string;
}

export interface TextBoxStoreType extends StoreType {
  updateState: (...args: any) => void;
  notifier: (action: number, data: OverlayDataType) => void;
}

const InitialState: StoreType = {
  textBoxOverlayData: [],
  text: '',
  color: '#000000',
  fontSize: 12,
  date: '',
  handleMarkupAction: null,
  textBoxObj: null,
  box_color: '#EBEBEB'
};

const useTextBoxStore = create<TextBoxStoreType>((set, get) => ({
  ...InitialState,

  updateState: (updatedState: any) => set((state: TextBoxStoreType) => ({ ...state, ...updatedState })),
  notifier: (action, data) => {
    const { updateState, textBoxOverlayData, handleMarkupAction } = get();

    if (action === LABEL_ACTIONS?.CREATED) {
      const overlayData = [...textBoxOverlayData, data];

      updateState({ textBoxOverlayData: overlayData });

      if (handleMarkupAction) {
        handleMarkupAction(LABEL_ACTIONS?.CREATED, MARKUP_TYPE.TEXT_BOX, data);
      }
    }

    if (action === LABEL_ACTIONS?.DELETED) {
      let overlayToDelete = {};
      const overlayData = textBoxOverlayData?.filter(item => {
        if (item?.properties?.tool_id === data?.properties?.tool_id) {
          overlayToDelete = item;
        }
        return item?.properties?.tool_id !== data?.properties?.tool_id;
      });

      updateState({ textBoxOverlayData: overlayData });

      if (handleMarkupAction) handleMarkupAction(LABEL_ACTIONS?.DELETED, MARKUP_TYPE.TEXT_BOX, overlayToDelete);
    }

    if (action === LABEL_ACTIONS?.EDITED || action === LABEL_ACTIONS?.MOVED) {
      let updatedData = {};
      const overlayData = textBoxOverlayData?.map(item => {
        if (item.properties?.tool_id === data?.properties?.tool_id) {
          item = { ...item, ...data };
          updatedData = item;
        }
        return item;
      });

      updateState({ textBoxOverlayData: overlayData });

      if (handleMarkupAction) handleMarkupAction(LABEL_ACTIONS?.EDITED, MARKUP_TYPE.TEXT_BOX, updatedData);
    }
  }
}));

export default useTextBoxStore;
