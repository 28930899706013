// @ts-nocheck

import { StyleProvider as StemStyleProvider } from '@attentive-platform/stem-ui';

type Props = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  theme?: any;
};

export const StyleProvider = ({ children, theme }: Props) => {
  return <StemStyleProvider theme={theme}>{children}</StemStyleProvider>;
};
