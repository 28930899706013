/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
import { GEOMETRY_TYPES_BY_INT, GEOMETRY_TYPES_FEATURE, MEASUREMENT_TYPE_KEY, MEASUREMENT_TYPE_UNIT } from 'woodpecker';
import _ from 'lodash';
import { generateUniqueID } from 'macaw';
import { Feature } from 'ol';
import { LiveMeasurementStoreType } from '../../../store/liveMeasurementStore';
import { formatArea, formatLength } from '../helpers';
import { regenerateMeasurements } from '../../../helpers/helpers';

type KeyType =
  | 'count'
  | 'length'
  | 'area'
  | 'perimeter'
  | 'edit_count'
  | 'edit_length'
  | 'edit_area'
  | 'edit_perimeter';

// Geometry types: point-> 1, line-> 2, polygon-> 3, abstract-> 4, typical-> 5
type geometryType = 1 | 2 | 3 | 4 | 5;

const getMeasurementsList = (geometry_type: number, measurement_type: number) => {
  if (geometry_type === GEOMETRY_TYPES_FEATURE.POLYGON)
    return [
      {
        name: 'edit_area',
        value: 0,
        unit: 'sq ft'
      },
      {
        name: 'area',
        value: 0,
        unit: 'sq ft'
      },
      {
        name: 'edit_perimeter',
        value: 0,
        unit: 'ft'
      },
      {
        name: 'perimeter',
        value: 0,
        unit: 'ft'
      }
    ];
  if (geometry_type === GEOMETRY_TYPES_FEATURE.POINT)
    return [
      {
        name: 'edit_count',
        value: 0,
        unit: 'count'
      },
      {
        name: 'count',
        value: 0,
        unit: 'count'
      }
    ];
  if (geometry_type === GEOMETRY_TYPES_FEATURE.LINE)
    return [
      {
        name: 'edit_length',
        value: 0,
        unit: 'ft.'
      },
      {
        name: 'length',
        value: 0,
        unit: 'ft.'
      }
    ];
  if (geometry_type === GEOMETRY_TYPES_FEATURE.TYPICAL)
    return [
      {
        name: 'edit_count',
        value: 0,
        unit: 'count'
      },
      {
        name: 'count',
        value: 0,
        unit: 'count'
      }
    ];

  return [
    {
      name: MEASUREMENT_TYPE_KEY[measurement_type],
      value: 0,
      unit: MEASUREMENT_TYPE_UNIT[MEASUREMENT_TYPE_KEY[measurement_type] as KeyType]
    }
  ];
};

export const shapelyObj = ({ itemDetails, geojson }: any) => {
  const measurements = getMeasurementsList(itemDetails.geometry_type, itemDetails.measurement_type);
  return {
    output_geojson: geojson,
    feature: {
      name: itemDetails.layer_type,
      feature_id: itemDetails.id,
      geometry_type: itemDetails.geometry_type,
      is_custom: true,
      is_assembly_feature: false,
      default_tags: itemDetails?.default_tags || {}
    },
    id: itemDetails.id,
    measurements,
    assemblies: [],
    is_active: true
  };
};

export const measurementFormat = (
  measurements: {
    name: string;
    value: number;
    unit: string;
  }[]
) => {
  let format = { total_measurement: +measurements[0].value.toFixed(2) };
  measurements?.forEach(element => {
    format = { ...format, [element.name]: element.value };
  });
  return format;
};

export const generateAerialMeasurements = (feature: any) => {
  const properties = [];
  const geometry = feature.getGeometry();
  const area = formatArea(geometry, 1, 1);
  const perimeter = formatLength(geometry, 1, 1);

  properties.push({ key: 'area', value: area });
  properties.push({ key: 'perimeter', value: perimeter });

  return properties;
};

export const getMeasurements = ({
  layersPointCount,
  scale,
  layers,
  map,
  updateState
}: {
  layersPointCount: any;
  scale: number;
  layers: { data: Map<string, any> };
  map: any;
  updateState: LiveMeasurementStoreType['updateStateWithPrevValue'];
}) => {
  const array = [];
  let res = {};
  let default_tags = {};
  let geojsonRec = {};
  const measurements = new Map();
  let layerChanged = {};
  const currentFeatures = {};
  for (const [key, value] of Object.entries(layersPointCount)) {
    array.push(value);
  }

  const scaleValue = scale !== null ? scale : 0;
  updateState(({ ...args }: LiveMeasurementStoreType) => ({
    ...args,
    isMeasurementsLoading: true
  }));

  const output = regenerateMeasurements(array, scaleValue);

  output.then(result => {
    result?.geojson?.map((layer: any) => {
      if (layer.measurements?.length) {
        res = {
          ...res,
          [`${layer.id}`]: measurementFormat(layer.measurements)
        };
      }
      default_tags = {
        ...default_tags,
        [layer?.id]: layer?.default_tags || {}
      };
      layer.output_geojson.features.forEach((feat: any) => {
        measurements.set(`${feat.id}-${layer?.id}`, {
          ...feat.properties
        });
      });
      geojsonRec = { ...geojsonRec, [`${layer.id}`]: layer.output_geojson };
      layerChanged = { ...layerChanged, [`${layer.id}`]: true };
    });

    for (const [id, geo] of Object.entries(geojsonRec)) {
      const featureArray: any[] = [];
      const vector_layer = map?.getAllLayers().filter((layer: any) => id === layer.get('id'));
      if (vector_layer && vector_layer.length && layers.data?.get(id)) {
        const geometry_type_layer: geometryType = layers?.data?.get(id)?.geometry_type || 0;
        vector_layer[0]
          ?.getSource()
          ?.getFeatures()
          ?.map((feature: Feature) => {
            const id = feature.getId();
            if (!id) feature?.setId(generateUniqueID());

            if (
              !!geometry_type_layer &&
              GEOMETRY_TYPES_BY_INT[geometry_type_layer] !== feature?.getGeometry()?.getType()
            ) {
              vector_layer[0].getSource()?.removeFeature(feature);
            }

            const uuid = `${id}-${vector_layer[0]?.get('id')}`;
            feature.setProperties(measurements.get(uuid), true);
            featureArray.push({
              feature_id: id,
              ...measurements.get(uuid),
              featureProperties: measurements.get(uuid)
            });
          });
      }
      Object.assign(currentFeatures, { [`${id}`]: featureArray });
    }

    updateState(({ layersPointCount, allFeaturesObj, ...prev }: LiveMeasurementStoreType) => ({
      ...prev,
      layersPointCount: { ...layersPointCount, ...res },
      layerChanged,
      isMeasurementsLoading: false,
      allFeaturesObj: { ...allFeaturesObj, ...currentFeatures },
      default_tags
    }));
  });
};
