import React, { useMemo } from 'react';
import { Typography, Divider, Tooltip } from 'ui';
import MoveIcon from 'ui/src/Assets/MoveIcon';
import CopyAndMoveIcon from 'ui/src/Assets/CopyAndMoveIcon';
import DeleteIcon from 'ui/src/Assets/DeleteIcon';
import MergeIcon from 'ui/src/Assets/MergeIcon';
import { GEOMETRY_TYPE, Tool } from 'woodpecker';
import AssignFeatureIcon from 'ui/src/Assets/AssignFeatureIcon';
import useMapStore from '../../store/mapStore';
import { ACTION, TOOL_TYPE } from '../../js-layer/toolLayer/constants';
import { executeAction } from '../../js-layer/mapLayer/mapInit';
import { SelectMenuContainer, iconContainer, iconButton, disabled } from './styles';

interface SelectMenuProps {
  top: number;
  left: number;
  onClose: () => void;
  setSelectedTool: (tool: Tool) => void;
}

const SelectMenu: React.FC<SelectMenuProps> = ({ top, left, onClose, setSelectedTool }) => {
  const selectedFeatures = useMapStore(state => state.selectedFeatures);
  const setSelectedFeatures = useMapStore(state => state.setSelectedFeatures);
  const selectedTool = useMapStore(state => state.tool);
  const layers = useMapStore(state => state.layers);
  const isMultiSelectionEnable = useMapStore(state => state.toolsSetting?.allowSelectionAcrossLayers);
  const count = selectedFeatures?.length ?? 0;

  const vector_layer_id = useMemo(() => {
    if (!selectedFeatures?.length) return '';

    const set = new Set<string>();
    for (let index = 0; index < selectedFeatures.length; index++) {
      const feature = selectedFeatures[index];
      const id = feature?.getProperties()?.vector_layer_id;
      const isTempLayer = feature?.getProperties()?.temp_layer;
      if (layers.has(id) || isTempLayer) set.add(id);
      else return '';
    }
    return [...set][0];
  }, [selectedFeatures, layers]);

  const SELECT_MENU_ITEMS: Tool[] = [
    {
      title: 'Delete',
      icon: () => <DeleteIcon />,
      tool_id: ACTION.DELETE,
      is_action: true,
      shortcut: 'd'
    },
    {
      title: 'Move',
      icon: () => <MoveIcon />,
      tool_id: TOOL_TYPE.MOVE,
      is_action: false,
      shortcut: 'm'
    },
    {
      title: 'Copy and Move',
      icon: () => <CopyAndMoveIcon />,
      tool_id: TOOL_TYPE.COPY_AND_MOVE,
      is_action: false,
      shortcut: 'ctrl+m'
    }
  ];

  if (vector_layer_id) {
    SELECT_MENU_ITEMS.unshift({
      title: 'Reclassify',
      icon: () => <AssignFeatureIcon />,
      tool_id: TOOL_TYPE.PANEL_CLICK,
      is_action: false
    });
    const geometry_type = layers?.get(vector_layer_id)?.geometry_type || 0;
    if (geometry_type === GEOMETRY_TYPE.POLYGON) {
      SELECT_MENU_ITEMS.push({
        title: 'Merge',
        icon: () => <MergeIcon />,
        tool_id: ACTION.MERGE,
        is_action: true,
        shortcut: 'c'
      });
    }
  }

  const handleClick = (tool: Tool) => {
    setSelectedFeatures([...(selectedFeatures || [])]);
    if (tool.is_action) executeAction(tool.tool_id);
    else
      setSelectedTool({
        ...selectedTool,
        ...tool,
        clickId: Math.random().toString(36).substring(7),
        vector_layer_id,
        lastTool: { ...selectedTool }
      });

    onClose();
  };

  if (!selectedFeatures?.length) return <></>;

  return (
    <SelectMenuContainer top={top} left={left}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
          Selected
        </Typography>
        <Typography variant='body2' sx={{ lineHeight: '1.375rem' }}>
          {count} selected
        </Typography>
      </div>
      <Divider orientation='vertical' flexItem />
      <div>
        <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
          Tools
        </Typography>
        <div className={iconContainer}>
          {SELECT_MENU_ITEMS?.map(tool => {
            let toolTipData = tool?.title;
            if (tool?.shortcut) toolTipData += ` ( ${tool?.shortcut?.split(' ').join('+')} )`;
            return (
              <Tooltip key={tool.tool_id} title={toolTipData}>
                <div
                  className={`${iconButton} ${
                    isMultiSelectionEnable && [TOOL_TYPE.PANEL_CLICK, ACTION.MERGE].includes(tool.tool_id)
                      ? disabled
                      : ''
                  }`}
                  onClick={() => handleClick(tool)}>
                  {tool.icon ? tool.icon(false) : ''}
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </SelectMenuContainer>
  );
};

export default SelectMenu;
