import { showToast } from 'ui';
import removeFeatures from 'macaw/src/removeFeature';
import Feature from 'ol/Feature';
import { Select } from 'ol/interaction';
import MapBase from '../../mapLayer/mapBase';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { PARCEL } from '../../../hooks/tools/helpers/constants';

class Delete {
  private mapObj: MapBase;

  private features: Feature[];

  private layer: any;

  private featuresMap: Map<string, Feature[]>;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
    this.featuresMap = new Map<string, Feature[]>();
    this.features = [];
  }

  removeHiddenGeometries() {
    let select: Select | null = null;
    this.mapObj?.map?.getInteractions().forEach((interaction: any) => {
      if (interaction instanceof Select) {
        select = interaction;
      }
    });

    const layerRecord = new Map();
    const selectedFeature = globalStore.AppStore.selectedFeatures;
    const filteredFeatures = selectedFeature?.filter((feature: Feature) => {
      const vector_layer_id = feature?.get('vector_layer_id');
      let isVisible = true;
      if (layerRecord?.has(vector_layer_id)) {
        const layer = layerRecord?.get(vector_layer_id);
        isVisible = isVisible && layer?.getVisible();
      } else {
        const layer = this.mapObj?.getLayerById(vector_layer_id);
        layerRecord?.set(vector_layer_id, layer);
        isVisible = isVisible && layer?.getVisible();
      }
      if (!isVisible) {
        select?.getFeatures()?.remove(feature);
      }
      return isVisible;
    });

    return filteredFeatures;
  }

  execute() {
    this.features = this.removeHiddenGeometries();
    globalStore.AppStore.setSelectedFeatures([]);
    this.layer = this.mapObj?.getLayerById(globalStore.AppStore.selectedLayerID);
    this.features.map(feature => {
      const layerId = feature?.getProperties().vector_layer_id;
      if (this.featuresMap?.has(layerId)) this.featuresMap?.get(layerId)?.push(feature);
      else this.featuresMap?.set(layerId, [feature]);
    });
    this.deleteFeatures();
  }

  deleteFeatures = () => {
    const features = this.features ?? [];
    if (!features.length) {
      showToast('No features selected', 'error');
    } else {
      this.featuresMap?.forEach((value: any, key) => {
        const layer = this.mapObj?.getLayerById(key);
        if (layer.get('id') === PARCEL) return;
        if (layer) {
          const source = layer?.getSource();
          removeFeatures(source, value);
        }
      });
      undoRedoPush();
      // showToast(
      //   `Deleted ${features.length} features of ${this.layer.get("id")}`,
      //   "success"
      // );
    }

    this.featuresMap = new Map<string, Feature[]>();
    // this.mapObj.AppStore.setUpdateMapLegend();
  };
}

export default Delete;
