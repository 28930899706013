import { DragBox } from 'ol/interaction';
import { postAPI } from 'utils';
import { TEXT_DETECTION_ENDPOINT } from 'utils/src/constants';
import { showToast } from 'ui';
import ImageLayer from 'ol/layer/Image';
import { Extent } from 'ol/extent';
import { copyTextToClipboard } from '../../../hooks/helpers';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';

class TextDetection extends ToolAbstract {
  private mapObj: MapBase;

  private drag: DragBox | null = null;

  private loading: boolean;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.loading = false;
  }

  /**
   * Initializes the tool for the specified layer ID.
   * Turns off any existing tool and sets up the draw interaction for the layer's source.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init = (id: string) => {
    const newDrag = new DragBox({
      className: 'dragboxStyle'
    });
    this.mapObj.map?.addInteraction(newDrag);
    this.drag = newDrag;

    newDrag.on('boxend', () => {
      if (this.loading)
        return showToast('Text detection is in progress', 'info', {
          autoClose: 500,
          hideProgressBar: true
        });

      const extent = newDrag.getGeometry().getExtent();
      const { map } = this.mapObj;
      if (!map) return;
      const imageLayer = map
        .getLayers()
        .getArray()
        .find(layer => layer instanceof ImageLayer);

      if (imageLayer) {
        const imageSource = imageLayer.getSource();
        const imageExtent = imageSource.getImageExtent();
        this.createSnapshot(imageSource.getUrl(), imageExtent, extent, async (url: any) => {
          try {
            this.loading = true;
            const response: any = await postAPI(TEXT_DETECTION_ENDPOINT, {
              image_base64: url
            });

            copyTextToClipboard(response?.text, 'Text copied to clipboard');
          } catch (e) {
            showToast('Text could not be copied. Please try again!', 'error', {
              autoClose: 1000,
              hideProgressBar: true
            });
          } finally {
            this.loading = false;
          }
        });
      }
    });
  };

  createSnapshot(url: string, imageExtent: Extent, extent: Extent, callback: (url: string) => void) {
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Ensure CORS support
    img.src = url;
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Calculate proportions of the selected extent within the image extent
      const [imgMinX, imgMinY, imgMaxX, imgMaxY] = imageExtent;
      const [selMinX, selMinY, selMaxX, selMaxY] = extent;

      const cropX = ((selMinX - imgMinX) / (imgMaxX - imgMinX)) * imageWidth;
      const cropY = ((imgMaxY - selMaxY) / (imgMaxY - imgMinY)) * imageHeight;
      const cropWidth = ((selMaxX - selMinX) / (imgMaxX - imgMinX)) * imageWidth;
      const cropHeight = ((selMaxY - selMinY) / (imgMaxY - imgMinY)) * imageHeight;

      // Create a canvas and extract the region directly from the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx?.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

      // return the snapshot url in callback
      const snapshotURL = canvas.toDataURL();
      callback(snapshotURL);
    };
  }

  /**
   * Turns off the drag interaction by removing it from the map and removing event listeners.
   */
  off() {
    this.mapObj.map?.removeInteraction(this.drag as DragBox);
  }
}

export default TextDetection;
