import { RefObject } from 'react';
import { cloneDeep } from 'lodash';
import { convertValue, measurementConversions, METRIC_SYSTEM_ENUM } from 'woodpecker';
import useMapStore from '../../store/mapStore';
import TooltipData from './component/TooltipData';

const ToolTip = ({ toolTipRef }: { toolTipRef: RefObject<HTMLDivElement> }) => {
  const toolTipData = useMapStore(state => state.toolTipData);
  const measurementSystem = useMapStore(state => state?.measurementSystem);
  const updatedTooltipData = cloneDeep(toolTipData);
  
  if (measurementSystem === METRIC_SYSTEM_ENUM.METRIC) {
    updatedTooltipData.forEach(data => {
      const properties = data?.properties;
      properties.forEach(property => {
        const { key } = property;
        const { value } = property;
        Object.keys(measurementConversions).forEach(measureKey => {
          if (measureKey === key) {
            const newValue = convertValue(value, measurementConversions[key]);
            property.value = newValue;
          }
        });
      });
    });
  }

  return (
    <div ref={toolTipRef} id='tooltipElementRef' className='tooltip-menu'>
      {updatedTooltipData.map((data, index: number) => {
        return <TooltipData key={`tooltip-${data.geometry_type}-${index}`} data={data} index={index} />;
      })}
    </div>
  );
};

export default ToolTip;
