import { useEffect, useState } from 'react';
import useMapStore from '../../store/mapStore';
import { ClusterSuggestionPanelProps } from './types';
import ConfirmationModal from './ConfirmationModal';
import { useShallow } from 'zustand/react/shallow';

export const ClusterSuggestionPanel = ({
  allowLoad,
  limitValidationProcessing,
  setAllowLoad
}: ClusterSuggestionPanelProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hideLayers, setHideLayers] = useState<string[]>([]);
  const { notVisibleFeatures, setNotVisibleFeatures } = useMapStore(
    useShallow(state => ({
      notVisibleFeatures: state.notVisibleFeatures,
      setNotVisibleFeatures: state.setNotVisibleFeatures
    }))
  );

  const map = useMapStore(state => state.map);
  const layers = useMapStore(state => state.layers);

  const closeModal = () => {
    setIsModalOpen(false);
    // this needs to be confirmed if this is required
    // features were not visible on cancel
    map?.getAllLayers().forEach(layer => {
      if (hideLayers.includes(layer.get('id'))) {
        layer.setVisible(false);
      } else layer.setVisible(true);
    });
    setAllowLoad(true);
  };

  const handleConfirm = () => {
    map?.getAllLayers().forEach(layer => {
      if (hideLayers.includes(layer.get('id'))) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
      }
    });
    setNotVisibleFeatures([...hideLayers]);
    closeModal();
  };

  useEffect(() => {
    setHideLayers(notVisibleFeatures);
  }, [notVisibleFeatures]);

  if (allowLoad || limitValidationProcessing) {
    return null;
  }

  return (
    <div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={closeModal}
        layers={layers}
        hiddenLayers={hideLayers}
        setHideLayers={setHideLayers}
        message='The data is too large to load fully at once. Please select a few layers to hide, which you can unhide later.'
      />
    </div>
  );
};
