import React, { useRef } from 'react';
import { Button } from 'ui';
import { GEOMETRY_TYPES_FEATURE, Layer, MEASUREMENT_TYPE_NAME } from 'woodpecker';
import { getId } from '../../hooks/helpers';
import useMapStore from '../../store/mapStore';

type ContextMenuProps = {
  selectedLayer: Layer;
  abstractValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContextCancel: () => void;
  onContextSave: () => void;
};

const ContextMenu = ({
  selectedLayer,
  abstractValue,
  handleInputChange,
  onContextCancel,
  onContextSave
}: ContextMenuProps) => {
  const map = useMapStore(state => state.map);
  const contextMenuRef = useRef<HTMLDivElement | null>(null);

  return (
    <div id='context-menu' className='abstract-menu' ref={contextMenuRef}>
      <p className='abstract-menu-name'>{selectedLayer?.layer_type}</p>
      <div className='abstract-menu-table'>
        <div className='abstract-table-layout '>
          <div>ID</div>
          <div style={{ textTransform: 'capitalize' }}>
            {MEASUREMENT_TYPE_NAME[selectedLayer?.measurement_type ?? 1]}
          </div>
        </div>
        <div className='abstract-table-layout' style={{ background: '#fff' }}>
          <div id='context-menu-id'>
            {selectedLayer?.geometry_type === GEOMETRY_TYPES_FEATURE.ABSTRACT ? getId(map, selectedLayer?.id) : ''}
          </div>
          <div>
            <input
              className='inputStyles'
              value={abstractValue}
              type='number'
              onChange={handleInputChange}
              onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            />
          </div>
        </div>
      </div>

      <div className='context-menu-btn-container'>
        <Button
          size='small'
          color='primary'
          variant='outlined'
          style={{ width: '82px' }}
          id='context-cancel'
          className='context-menu-button-styles'
          onClick={onContextCancel}>
          Cancel
        </Button>
        <Button
          size='small'
          color='primary'
          id='context-save-btn'
          style={{ width: '82px' }}
          className='context-menu-button-styles'
          onClick={onContextSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ContextMenu;
