import { Coordinate } from "ol/coordinate";
import { generateUniqueID } from "../miscellaneous";

export type GeojsonType = {
  type: string;
  id: string;
  geometry: {
    type: string;
    coordinates: any[];
  };
  properties: any;
};

const getMidCoordinate = (coordinates: Coordinate[]) => {
  let x = 0,
    y = 0;
  // It assumes that coordinates is array of 5 Coordinate
  coordinates.forEach((coord: Coordinate, index) => {
    if (index === coordinates.length - 1) return;
    x += coord[0];
    y += coord[1];
  });
  return [x / 4, y / 4];
};

const getPolygonToPoint = (
  featureGeojson: GeojsonType[],
  feature_id: string
) => {
  const featuresArray: GeojsonType[] = [];
  featureGeojson?.forEach((feature, index: number) => {
    let temp = { ...feature };
    const id = generateUniqueID("point") + index;
    const geometry = {
      type: "Point",
      coordinates: getMidCoordinate(temp?.geometry?.coordinates[0]),
    };
    temp = {
      ...temp,
      id: id,
      geometry: geometry,
      properties: {
        count: 1,
        vector_layer_id: feature_id,
      },
    };
    featuresArray?.push(temp);
  });

  return featuresArray;
};

export default getPolygonToPoint;
