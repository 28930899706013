import React from "react";

const UpArrow = ({ fill = "#4D4D4D" }) => {
  return (
    <svg
      width="0.688rem"
      height="0.5rem"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49999 0.793945L10.667 5.93995L9.43799 7.16895L5.49999 3.23095L1.56199 7.16895L0.332993 5.93995L5.49999 0.793945Z"
        fill={fill}
      />
    </svg>
  );
};

export default UpArrow;
