import React from "react";

const ToolBarDropIcon = ({ fill = "#4D4D4D" }) => {
  return (
    <svg
      width="0.5rem"
      height="0.313rem"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4.66699L0 0.666992H8L4 4.66699Z" fill={fill} />
    </svg>
  );
};

export default ToolBarDropIcon;
