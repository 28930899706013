import { GEO_JSON } from 'macaw';
import { DIMENSION_TOOL_LAYER, HIGHLIGHT_TOOL_LAYER, UNDO_REDO_STACK_LIMIT } from 'woodpecker';
import { showToast } from '@attentive-platform/stem-ui';
import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';

// TODO: Add layer tracker

class UndoRedo {
  private mapObj: MapBase;

  private stack: Array<any> = [];

  private stackPointer: number = -1;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
  }

  /**
   * Push initial state of map
   */
  init(): void {
    this.push();
  }

  reset(): void {
    this.stack = [];
    this.stackPointer = -1;
  }

  /**
   * Push current state of map
   * Call this method on different operations
   */
  push = (msg = ''): void => {
    try {
      const src = { msg, outputs: this.getLayersGeojson() as any };
      const srcStr = JSON.stringify(src);
      const lastRecordStr = JSON.stringify(this.stack[this.stackPointer]);
      const isLastRecordSame = lastRecordStr ? srcStr === lastRecordStr : false;

      // Check if last element of undo stack and incoming element are not same
      if (src?.outputs.length && !isLastRecordSame) {
        const removeItems = this.stack.length - this.stackPointer - 1;

        if (this.stack.length > UNDO_REDO_STACK_LIMIT) {
          this.stack.shift();
          this.stackPointer = this.stack.length - 1;
        }

        this.stackPointer++;
        this.stack.splice(this.stackPointer, removeItems, src);
      }
    } catch (err) {
      // Assuming setExtra, setTrackTools, and captureException functions exist
      //   setExtra("undo_redo_stack", JSON.stringify(this.stack));
      //   setExtra("Request ID", localStorage.getItem("job_id"));
      //   captureException(err);
    }
  };

  /**
   * Retrieves the GeoJSON representation of features from layers in the map.
   * @returns {Array<{ id: string, geojson: any }>} An array of objects containing the layer ID and its GeoJSON representation.
   */
  getLayersGeojson(): Array<{ id: string; geojson: any }> {
    const layers = globalStore.AppStore.layers || [];
    const outputs: { id: string; geojson: any }[] = [];

    //Pushing the feature layers as well as dimension and highlight tool layers to the stack
    [...layers.keys(), DIMENSION_TOOL_LAYER, HIGHLIGHT_TOOL_LAYER].forEach((lId: string) => {
      const layer = this.mapObj.getLayerById(lId);

      if (layer) {
        const features = layer.getSource().getFeatures();
        const layerGeojson = GEO_JSON.writeFeatures(features);
        const dict = {
          id: lId,
          geojson: JSON.parse(layerGeojson)
        };
        outputs.push(dict);
      }
    });

    return outputs;
  }

  /**
   * Sets features for layers on the map based on the provided GeoJSON data.
   * @param {Array<{ id: string, geojson: any }>} layers - An array of objects containing the layer ID and its GeoJSON representation.
   * @returns {void}
   */
  setFeatures(layers: any): void {
    if (layers?.length) {
      layers.forEach((l: any) => {
        const prevLayer = this.mapObj.getLayerById(l.id);

        prevLayer.getSource().clear();
        prevLayer.getSource().addFeatures(GEO_JSON.readFeatures(l.geojson));
      });
    }
  }

  /**
   * Undo the last action by reverting to the previous set of features for each layer.
   * @returns {void}
   */
  undo = (): void => {
    if (this.stackPointer > 0) {
      const { msg } = this.stack[this.stackPointer] || {};

      this.stackPointer--;
      const { outputs: layers } = this.stack[this.stackPointer] || {};
      if (msg) {
        showToast(msg, 'info');
      }
      this.setFeatures(layers);
    }
  };

  /**
   * Redo the last undone action by reverting to the next set of features for each layer.
   * @returns {void}
   */
  redo = (): void => {
    if (this.stackPointer < this.stack.length - 1) {
      this.stackPointer++;
      const { outputs: layers, msg } = this.stack[this.stackPointer] || {};
      if (msg) {
        showToast(msg, 'info');
      }

      this.setFeatures(layers);
    }
  };
}

export default UndoRedo;
