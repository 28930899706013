import React from "react";

export const ThicknessIcon = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#4D4D4D"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.25 5C4.25 4.58579 4.58579 4.25 5 4.25H19C19.4142 4.25 19.75 4.58579 19.75 5C19.75 5.41421 19.4142 5.75 19 5.75H5C4.58579 5.75 4.25 5.41421 4.25 5ZM4.25 9C4.25 8.0335 5.0335 7.25 6 7.25H18C18.9665 7.25 19.75 8.0335 19.75 9V10C19.75 10.9665 18.9665 11.75 18 11.75H6C5.0335 11.75 4.25 10.9665 4.25 10V9ZM6 8.75C5.86193 8.75 5.75 8.86193 5.75 9V10C5.75 10.1381 5.86193 10.25 6 10.25H18C18.1381 10.25 18.25 10.1381 18.25 10V9C18.25 8.86193 18.1381 8.75 18 8.75H6ZM6 13.25C5.0335 13.25 4.25 14.0335 4.25 15V18C4.25 18.9665 5.0335 19.75 6 19.75H18C18.9665 19.75 19.75 18.9665 19.75 18V15C19.75 14.0335 18.9665 13.25 18 13.25H6ZM5.75 15C5.75 14.8619 5.86193 14.75 6 14.75H18C18.1381 14.75 18.25 14.8619 18.25 15V18C18.25 18.1381 18.1381 18.25 18 18.25H6C5.86193 18.25 5.75 18.1381 5.75 18V15Z"
        fill="inherit"
      />
    </svg>
  );
};
