import React from "react";

const PencilIcon = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#4D4D4D"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="pencil-fill">
        <path
          id="Vector"
          d="M10.7496 5.71232L14.2851 9.24788L6.03553 17.4975H2.5V13.9619L10.7496 5.71232ZM11.9281 4.53381L13.6958 2.76605C14.0213 2.44061 14.5489 2.44061 14.8743 2.76605L17.2314 5.12307C17.5568 5.44851 17.5568 5.97615 17.2314 6.30158L15.4636 8.06935L11.9281 4.53381Z"
          fill="inherit"
        />
      </g>
    </svg>
  );
};

export default PencilIcon;
