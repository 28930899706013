import { BEAM_USER, BRIGHTVIEW_USER, REXEL_USER, USAGE_MODE } from '../Constants/Constant';
import { useUserDetails } from '../Stores/UserDetails';

export const isRexelUser = () => {
  return window.location.hostname.startsWith(REXEL_USER);
};

export const isBeamUser = () => {
  const domain = window.location.hostname.split('.');
  return domain.includes(BEAM_USER);
};

export const isBrightviewUser = () => {
  const domain = window.location.hostname.split('.');
  return domain.includes(BRIGHTVIEW_USER);
};

// export const isAttentiveAppUser = () => {
//   // checks which might be needed in future
//   // !isBeamUser() && !isBrightviewUser() && !isRexelUser();

//   const domain = window.location.hostname;
//   const isAttentiveDomain = domain.endsWith('app.attentive.ai'); // this will be true for any domain that ends with app.attentive.ai
//   const isLocalhost = domain === 'localhost';
//   const isBrightviewLocalHostDomain = domain.endsWith(`${BRIGHTVIEW_USER}.localhost`);

//   return isAttentiveDomain || isLocalhost || isBrightviewLocalHostDomain;
// };

/**
 * A user is pro user if they are paid and is_lite_user flag is false for them
 * @returns true or false
 */
export const isProUser = (user: $TSFixMe) => {
  return user.is_in_free_trial?.is_subscribe && !user.is_lite_user;
};

/**
 * A user is lite user if they are paid and is_lite_user flag is true for them
 * @returns true or false
 */
export const isLiteUser = (user: $TSFixMe) => {
  return user.is_in_free_trial?.is_subscribe && user.is_lite_user;
};

export const determineUserMode = (): number => {
  const user = useUserDetails.getState()?.user;
  const isAccelerateUser = useUserDetails.getState()?.isAccelerateUser;

  const rexelUser = isRexelUser();
  const beamUser = isBeamUser();
  const notLoggedIn = Object.keys(user || {}).length === 0;

  // Check if the user is not logged in (no user data available).
  // In this case, bypass user-level checks and rely solely on URL-based logic.
  if (notLoggedIn) {
    return isAccelerateUser || rexelUser || beamUser ? USAGE_MODE.BLUEPRINT : USAGE_MODE.AERIAL;
  }

  if (isAccelerateUser || rexelUser || beamUser) {
    return USAGE_MODE.BLUEPRINT;
  }

  // Check if the user's company allows Blueprint takeoff
  const isBlueprintTakeoffAllowed = user?.company?.blueprint_takeoff_allowed ?? false;

  // If Blueprint takeoff is not allowed, fall back to Aerial mode
  if (!isBlueprintTakeoffAllowed) {
    return USAGE_MODE.AERIAL;
  }

  // If the user has construction takeoff enabled, use Blueprint mode; otherwise, Aerial mode
  const hasConstructionTakeoff = user?.construction_takeoff ?? false;
  return hasConstructionTakeoff ? USAGE_MODE.BLUEPRINT : USAGE_MODE.AERIAL;
};
