import React, { useCallback, useMemo } from 'react';
import { Tooltip } from 'ui';
import { ToolBarItemsTypes } from 'woodpecker';
import { iconButton } from '../styles';
import useMapStore, { Feature } from '../../../store/mapStore';
import { DEFAULT_TOOL } from 'woodpecker';
import { GEO_JSON } from 'macaw';

const CustomToolList = ({
  featuresMap,
  customToolBarItems
}: {
  featuresMap: Map<number, string[]>;
  customToolBarItems: ToolBarItemsTypes[];
}) => {
  const { setSelectedTool, selectedTool, selectedFeatures } = useMapStore(state => ({
    setSelectedTool: state.setTool,
    selectedTool: state?.tool,
    selectedFeatures: state.selectedFeatures
  }));

  const bptProjection = useMapStore(state => state.bptProjection);

  const TOOLS: ToolBarItemsTypes[] = useMemo(
    () =>
      customToolBarItems.filter((item: ToolBarItemsTypes) => {
        let available: boolean = false;
        for (let index = 0; index < item?.geometry_available?.length; index++) {
          available = available || featuresMap?.has(item.geometry_available[index]);
        }
        if (
          !available ||
          (!item.multi && featuresMap.size > 1)
          //    ||
          //   (isExcluded && !item.excludedItems)
        )
          return false;
        if (
          (item.single && featuresMap.size > 1) ||
          (item.single && item.single && featuresMap.entries().next().value[1].length > 1)
        ) {
          return false;
        }
        return true;
      }),
    [customToolBarItems, featuresMap]
  );

  const getGeojson = useCallback(() => {
    const features = selectedFeatures || [];

    let geometry_array: { [layerID: string]: Feature[] } = {};

    for (let index = 0; index < features.length; index++) {
      const feature = features[index];
      const layerID: string = feature?.getProperties()?.vector_layer_id;
      if (!!geometry_array?.[layerID]) {
        geometry_array[layerID] = [...geometry_array[layerID], feature];
      } else {
        geometry_array = { ...geometry_array, [layerID]: [feature] };
      }
    }

    let json = {};
    Object?.entries(geometry_array)?.map(([key, value]) => {
      json = {
        ...json,
        [key]: {
          ...JSON?.parse(GEO_JSON?.writeFeatures(value, bptProjection as any)),
          properties: {}
        }
      };
    });

    return json;
  }, [bptProjection, selectedFeatures]);

  const onHandleClick = useCallback(
    (tool: ToolBarItemsTypes) => {
      if (tool?.tool_id === 'copy_takeoff_data') {
        if (tool?.handleClick) tool?.handleClick(featuresMap, getGeojson());
      } else if (tool?.handleClick) tool?.handleClick(featuresMap);
      setSelectedTool({
        ...selectedTool,
        ...DEFAULT_TOOL,
        clickId: Math.random().toString(36).substring(7)
      });
      getGeojson();
    },
    [featuresMap, selectedTool, setSelectedTool, getGeojson]
  );

  return (
    <>
      {TOOLS?.map(tool => (
        <Tooltip key={tool.tool_id} title={tool.title}>
          <div className={iconButton} onClick={() => onHandleClick(tool)}>
            {tool.icon ? tool.icon(false) : ''}
          </div>
        </Tooltip>
      ))}
    </>
  );
};

export default CustomToolList;
