import React, { lazy, Suspense, useEffect, memo, useCallback } from 'react';
import { Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { captureException } from '@sentry/react';

import { COMPANY_CONFIG } from './Constants/Urls';
import { SharedViewLoader } from './Loaders/SharedViewLoader';
import { openAPI } from './Utils/ApiCalls';
import { SHARED_VIEW_PATHNAME } from './Constants/Constant';
import { detectUserOS, getParameterFromUrl } from './Utils/HelperFunctions';
import { isBeamUser, isRexelUser } from './Utils/usersTypes';
import { AttentiveLoader } from './Loaders/AttentiveLoader';
import { useCompanyConfig } from './Stores/CompanyConfig';
import { useUserDetails } from './Stores/UserDetails';
import { useDevice } from './Stores/Device';
import { useTitle } from './hooks/useTitle';
import './Styles/privateRoutes/style.less';
import './Styles/privateRoutes/automeasure.less';
import './Styles/privateRoutes/constructions.less';
import { useTags } from './Stores/Tags';
import { ACCELERATE_PATH, UNAUTH_PATHS } from './Constants/routes';
import useIdentifyUser from './hooks/useIdentifyUser';

const GoogleOAuthCallback = lazy(() => import('./LoginPage/GoogleOAuthCallback'));
const LinkedinOAuthCallback = lazy(() => import('./LoginPage/LinkedinOAuthCallback'));

const ThankYou = lazy(() => import('./LoginPage/ThankYou'));
const MainPage = lazy(() => import('./MainPage/MainPage'));
const Login = lazy(() => import('./LoginPage/Login'));

const Signup = lazy(() => import('./LoginPage/Signup'));
const ForgetPassword = lazy(() => import('./LoginPage/ForgetPassword'));
const ResetPassword = lazy(() => import('./LoginPage/ResetPassword'));
const SharedView = lazy(() => import('./SharedView/SharedView'));
const BlueprintSharedView = lazy(() => import('./SharedView/BlueprintSharedView'));
const BlueprintAccelerateDraft = lazy(() => import('./MainPage/Draft/BlueprintAccelerateDraft'));
const BlueprintAccelerateOutput = lazy(() => import('./MainPage/Output/BlueprintAccelerateOutput'));
const AccelerateCallback = lazy(() => import('./LoginPage/AccelerateCallback'));
const AccelerateLogout = lazy(() => import('./LoginPage/AccelerateLogout'));
const Logout = lazy(() => import('./LoginPage/Logout'));

const SHARED_VIEW_ROUTES = {
  [SHARED_VIEW_PATHNAME.AERIAL]: SharedView,
  [SHARED_VIEW_PATHNAME.BLUEPRINT]: BlueprintSharedView
};

const setFavicon = (beamUser: $TSFixMe) => {
  const faviconLink = document.querySelector('link[rel="shortcut icon"]');

  const faviconUrl = beamUser ? '/favicon-beam.png' : '/favicon-automeasure.ico';

  if (faviconLink) {
    // @ts-expect-error TS(2339): Property 'href' does not exist on type 'Element'.
    faviconLink.href = faviconUrl;
  }
};

const unAuthenticatedPaths = [...UNAUTH_PATHS];

const App = (props: $TSFixMe) => {
  // @ts-expect-error TS(2339): Property 'outputStore' does not exist on type 'Win... Remove this comment to see the full error message
  window.tagStore = useTags(useShallow(state => state));

  // Unauthenticated Routes
  // It maps a route to a component
  const ROUTES = [
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/signup',
      element: <Signup />
    },
    {
      path: '/forget-password',
      element: <ForgetPassword />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    },
    {
      path: '/oauth/google/callback',
      element: <GoogleOAuthCallback />
    },
    {
      path: '/oauth/linkedin/callback',
      element: <LinkedinOAuthCallback />
    },
    {
      path: '/thankyou',
      element: <ThankYou />
    }
  ];

  const [afterLoginRouteStore, isLoggedIn, dispatchUserDetails] = useUserDetails(
    useShallow(state => [state.afterLoginRoute, state.isLoggedIn, state.dispatch])
  );
  const [dispatchDeviceInfo] = useDevice(useShallow(state => [state.dispatch]));
  const [dispatchCompanyConfig] = useCompanyConfig(useShallow(state => [state.dispatch]));

  // Set title of the page based on URL
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  useTitle();

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  useIdentifyUser();

  const isAcceleratePath = pathname.startsWith(ACCELERATE_PATH[0]) || pathname.startsWith(ACCELERATE_PATH[1]);
  const rexelUser = isRexelUser();

  if (rexelUser) {
    unAuthenticatedPaths.push(...['/logout', '/login/attentive']);
    ROUTES.push(
      ...[
        {
          path: '/login/attentive',
          element: <Login />
        },
        {
          path: '/logout',
          element: <Logout />
        }
      ]
    );
  }

  const beamUser = isBeamUser();

  useEffect(() => {
    setFavicon(beamUser);
  }, [beamUser]);

  // DO NOT MOVE THIS CODE BLOCK FROM HERE [ORDER MATTERS]
  // render SharedViewComponent if current path matches /shared-view or '/construction/shared-view'
  if (Object.prototype.hasOwnProperty.call(SHARED_VIEW_ROUTES, pathname)) {
    const SharedViewComponent = SHARED_VIEW_ROUTES[pathname];
    return (
      <Suspense fallback={<SharedViewLoader init />}>
        <SharedViewComponent />
      </Suspense>
    );
  }

  // It saves a post login route in the store
  const setAfterLoginRoute = useCallback((route: any) => {
    dispatchUserDetails({ type: 'SET_AFTER_LOGIN_ROUTE', payload: route });
  }, []);

  // This will run for rexel users only
  useEffect(() => {
    if (rexelUser && !isLoggedIn) {
      const id = getParameterFromUrl('id');
      const token = getParameterFromUrl('token');
      const email = getParameterFromUrl('email');

      if (id && token && email) {
        const user = {
          id,
          token,
          email
        };
        dispatchUserDetails({
          type: 'SET_LOGIN',
          payload: { isLoggedIn: true, user }
        });
      }
    }
  }, [rexelUser, isLoggedIn]);

  useEffect(() => {
    
    const relativePathname = location.pathname.slice(-1) === '/' ? location.pathname.slice(0, -1) : location?.pathname;

    if (isLoggedIn) {
      // User is currently logged in but is trying to access one of the unauth path
      if (unAuthenticatedPaths.indexOf(relativePathname) > -1) {
        const afterLoginRoute = afterLoginRouteStore || '/';

        // Redirect user to afterLoginRoute or '/'
        // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
        if (!window.isEmbed) {
          navigate(afterLoginRoute);
        }

        // Clear afterLoginRoute once user is redirected
        if (afterLoginRoute !== '/') {
          setAfterLoginRoute('/');
        }
      }
    } else if (unAuthenticatedPaths.indexOf(relativePathname) === -1 && !isAcceleratePath) {
        const path = location?.pathname || '';
        const search = location?.search || '';

      // Set afterLoginRoute (user will be redirected to it after login)
      if (afterLoginRouteStore === '/') {
        setAfterLoginRoute(`${path + search}`);
      } else {
        setAfterLoginRoute('/');
      }

      navigate('/login');
    }
    // eslint-disable-next-line no-underscore-dangle
  }, [isLoggedIn]); // It will re-run when login status changes for example on logout

  // Fetch and set company configuration data such as the logo
  // For example, BV users see their own logo instead of Attentive's logo.
  useEffect(() => {
    dispatchDeviceInfo({ type: 'SET_USER_OS', payload: detectUserOS() });

    openAPI(COMPANY_CONFIG, { domain: window.location.hostname })
      .then(res => {
        dispatchCompanyConfig({ type: 'COMPANY_CONFIG', payload: res || {} });
      })
      .catch(err => {
        captureException(err);
      })
      .finally(() => {
        dispatchUserDetails({ type: 'SET_IS_LOADING_LOGO', payload: false });
      });
  }, []);

  /**
   * User Authenticated: render MainPage if user is currently logged in
   * User Unauthenticated: one of the unprotected route can be rendered
   */
  let content;
  if (isAcceleratePath) {
    content = (
      <Routes>
        <Route path='/auth/accelerate/callback' element={<AccelerateCallback />} />
        <Route path='/auth/accelerate/logout' element={<AccelerateLogout />} />
        <Route path='/blueprint/accelerate/request/:id' element={<BlueprintAccelerateOutput />} />
        <Route path='/blueprint/accelerate/draft/request/:id' element={<BlueprintAccelerateDraft />} />
      </Routes>
    );
  } else if (isLoggedIn) {
    content = <MainPage />;
  } else {
    content = (
      <Routes>
        {ROUTES.map(_route => (
          <Route key={_route.path} path={_route.path} element={_route.element} />
        ))}
      </Routes>
    );
  }

  return <Suspense fallback={<AttentiveLoader />}>{content}</Suspense>;
};
export default memo(App);
