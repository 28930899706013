import { useState, useEffect } from 'react';
import useSnapStore from '../../store/snapStore';

const useGeoJSON = (url?: string) => {
  const [geoJSON, setGeoJSON] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const setGeoJson = useSnapStore((state: any) => state.setGeoJson);

  useEffect(() => {
    if (!url) return;
    setLoading(true);
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setGeoJSON(data);
        setLoading(false);
        setGeoJson(data);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, [setGeoJson, url]);

  return { geoJSON, error, loading };
};

export default useGeoJSON;
