import { useCallback } from 'react';
import VectorSource from 'ol/source/Vector';
import {
  AUTO_SCALE_TOOL_LAYER,
  ARROW_TOOL,
  AbstractSvg,
  DIMENSION_TOOL_ID,
  GEOMETRY_TYPES,
  HIGHLIGHT_LAYER_COLOR,
  HIGHLIGHT_TOOL_LAYER,
  TypicalSvg,
  HAND_TOOL_LAYER,
  GEOMETRY_TYPE
} from 'woodpecker';
import { Stroke, Style, Fill, Circle, Icon, RegularShape } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import { LineString, MultiPoint, Point } from 'ol/geom';
import { Feature } from 'ol';
import { GEO_JSON } from 'macaw';
import useMapStore from '../store/mapStore';
import useMapHelpers, { hexToRgbArray } from './helpers';
import { formatLengthInFeetAndInches } from './tools/helpers';
import { LINE_COLOR, labelStyle, lineMeasureStyles } from './tools/helpers/styles';
import { LineSvg, ZERO_LENGTH } from './tools/helpers/constants';
import useTextBoxStore from '../store/textBoxStore';
import TOOL_MAP, { TOOL_TYPE } from '../js-layer/toolLayer/constants';
import { undoRedoPush } from '../js-layer/mapLayer/mapInit';

// Convert the React component to an SVG string
const getColor = (id: string, layers: Map<string, any>, currentOpacity: number) => {
  const color = layers?.get(id)?.color || 'black';
  const rgbColor = hexToRgbArray(color);
  rgbColor[3] = currentOpacity / 100;
  return { color, rgbColor };
};

export const styleFunctionDimenSionTool = (feature: any, scale: any, dpi: any) => {
  const styles = [...lineMeasureStyles];
  const geometry = feature.getGeometry() as any;
  const type = geometry.getType();
  if (type === GEOMETRY_TYPES.LINESTRING) {
    let count = 0;
    const totalSegment = geometry?.getCoordinates().length - 1;
    geometry?.forEachSegment((start: Coordinate, end: Coordinate) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      let point: Coordinate[] = [];
      if (count === 0) point = [start];
      if (count === totalSegment - 1) point.push(end);
      styles.push(
        new Style({
          geometry: new MultiPoint(point),
          image: new Icon({
            src: `data:image/svg+xml;utf8,${encodeURIComponent(LineSvg(LINE_COLOR))}`,
            anchor: [0.5, 0.5],
            rotateWithView: true,
            rotation: -rotation
          })
        })
      );
      count++;
      const segment = new LineString([start, end]);
      const label = formatLengthInFeetAndInches(segment, dpi, scale);
      if (label !== ZERO_LENGTH) {
        const _labelStyle = labelStyle.clone();
        _labelStyle.setGeometry(segment);
        _labelStyle.getText().setText(label);
        styles.push(_labelStyle);
      }
    });
  }
  return styles;
};

export const styleFunctionHightTool = (feature: Feature) => {
  const properties = feature.getProperties();
  const color = properties.color || HIGHLIGHT_LAYER_COLOR;
  const isAutoScaleLyr = properties.vector_layer_id === AUTO_SCALE_TOOL_LAYER;
  const rgbColor = hexToRgbArray(color);
  rgbColor[3] = 0.3;

  return new Style({
    ...(isAutoScaleLyr
      ? {
          stroke: new Stroke({
            color: '#995B00',
            width: 3
          })
        }
      : {}),

    fill: new Fill({
      color: rgbColor
    })
  });
};

export const styleFunctionHandTool = (feature: Feature) => {
  const properties = feature.getProperties();
  const color = properties.color || '#212121';
  const width = properties.width || 3;
  return new Style({
    stroke: new Stroke({
      color,
      width
    })
  });
};

export const styleFunctionArrow = (feature: any, color: string = 'red') => {
  const geometry = feature.getGeometry();
  const coords = geometry.getCoordinates();
  const [start, end] = [coords[coords.length - 2], coords[coords.length - 1]];
  const width = 3;

  const styles = [
    new Style({
      stroke: new Stroke({
        ...(color && { color }),
        ...(width && { width })
      })
    })
  ];

  if (!start) return styles;

  const dx = end[0] - start[0];
  const dy = end[1] - start[1];
  const rotation = Math.atan2(dy, dx);

  const arrowRadius = 2 * (width || 0);

  styles.push(
    new Style({
      geometry: new Point(end),
      image: new RegularShape({
        fill: new Fill({ color }),
        points: 3,
        radius: arrowRadius,
        rotation: -rotation,
        angle: Math.PI / 2 // rotate 90°
      })
    })
  );

  return styles;
};

export function ModifiedStyleGenFn(id: string, layers: Map<string, any>, currentOpacity = 30, geometryType: number) {
  const isTypical = geometryType === GEOMETRY_TYPE.TYPICAL;
  const isAbstract = geometryType === GEOMETRY_TYPE.ABSTRACT;

  // in case of line string, we need to set fill color to transparent
  // this case is for Line Rectangle and Line Circle tools
  const isLineString = geometryType === GEOMETRY_TYPE.LINESTRING;

  const { color, rgbColor } = getColor(id, layers, currentOpacity);

  const AbstarctIcon = AbstractSvg(color);
  const TypicalIcon = TypicalSvg(color);
  const displayIcon = isTypical || isAbstract;
  const iconToDisplay = isTypical ? TypicalIcon : AbstarctIcon;

  return new Style({
    stroke: new Stroke({
      color,
      width: 3
    }),
    fill: new Fill({
      color: isLineString ? 'rgba(255, 255, 255, 0)' : rgbColor
    }),
    // Add visualisation for point features
    image: displayIcon
      ? new Icon({
          src: `data:image/svg+xml;utf8,${encodeURIComponent(iconToDisplay)}`
        })
      : new Circle({
          radius: 7,
          fill: new Fill({
            color
          })
        })
  });
}

const useAddition = (layers?: Map<string, any>, onTriggerSave?: any) => {
  const map = useMapStore((state: any) => state.map);
  const { scale, dpi } = useMapStore((state: any) => state.worksheetParams);
  const selectObjRef = useMapStore(state => state.selectObjRef);
  const { getVectorLayerById } = useMapHelpers();
  const textBoxData = useTextBoxStore(state => state.textBoxOverlayData);

  const addVectorSource = useCallback(
    (_source: VectorSource, id: string) => {
      const vectorLayer = getVectorLayerById(id);
      if (vectorLayer) {
        vectorLayer.getSource().clear();
        vectorLayer.getSource().addFeatures(_source?.getFeatures());
      }
    },
    [getVectorLayerById]
  );

  const removeVectorLayer = useCallback(
    (id: string) => {
      const layer = getVectorLayerById(id);
      if (layer) {
        map.removeLayer(layer);
      }
    },
    [getVectorLayerById, map]
  );

  const addFeatureToSelect = useCallback(
    (id: string) => {
      const layer = getVectorLayerById(id);
      if (layer) {
        const source = layer?.getSource() as VectorSource;
        const features = source?.getFeatures();
        let present: boolean = false;
        features.forEach(feature => {
          const present_ = !!selectObjRef?.getFeatures().remove(feature);
          present = present || present_;
        });
        if (!present) {
          features.forEach(feature => {
            selectObjRef?.getFeatures().push(feature);
          });
        }
        selectObjRef?.dispatchEvent('select');
      }
    },
    [getVectorLayerById, selectObjRef]
  );

  const changeVisibilityOverlays = (external: boolean, visibility: boolean) => {
    const textBoxObj = TOOL_MAP[TOOL_TYPE.TEXT_BOX]?.getObject();
    const LAYERS = [DIMENSION_TOOL_ID, ARROW_TOOL, HIGHLIGHT_TOOL_LAYER, HAND_TOOL_LAYER];
    const styleFunctions: any = {
      [DIMENSION_TOOL_ID]: styleFunctionDimenSionTool,
      [ARROW_TOOL]: styleFunctionArrow,
      [HIGHLIGHT_TOOL_LAYER]: styleFunctionHightTool,
      [HAND_TOOL_LAYER]: styleFunctionHandTool
    };

    const styleFunctionsArgs: any = {
      [DIMENSION_TOOL_ID]: [scale, dpi],
      [ARROW_TOOL]: ['red'],
      [HIGHLIGHT_TOOL_LAYER]: [],
      [HAND_TOOL_LAYER]: []
    };

    LAYERS.forEach(layer_id => {
      const style = styleFunctions?.[layer_id];
      const args = styleFunctionsArgs[layer_id] || [];
      const layer = getVectorLayerById(layer_id);
      const source = layer?.getSource() as VectorSource;
      if (source) {
        const features = source?.getFeatures();

        features.forEach(feature => {
          const is_external = feature?.getProperties()?.external || false;
          if (external && is_external) {
            if (visibility) {
              feature.setStyle((feature: any) => style(feature, ...args));
            } else {
              feature.setStyle(new Style({}));
            }
          } else if (!external && !is_external) {
            if (visibility) {
              feature.setStyle((feature: any) => style(feature, ...args));
            } else {
              feature.setStyle(new Style({}));
            }
          }
        });
      }
    });

    if (visibility) {
      const data = textBoxData.filter(item => external === item.properties.external);
      textBoxObj?.loadLabelBoxes(data || []);
    } else {
      const overlays = [...(map?.getOverlays()?.getArray() || [])];
      if (overlays.length) {
        overlays.forEach(overlay => {
          if (overlay.get('labelBoxData')) {
            const data = overlay.get('labelBoxData');
            const is_external = data.properties.external || false;
            if ((external && is_external) || (!external && !is_external)) map?.removeOverlay(overlay);
          }
        });
      }
    }
  };

  const addFeaturesToLayer = (features: any[], layer_id: string) => {
    const layer = getVectorLayerById(layer_id);
    if (layer) {
      const source = layer?.getSource();
      const projection = map?.getView().getProjection();
      const json = {
        type: 'FeatureCollection',
        features: features || []
      };
      const featuresObj = GEO_JSON.readFeatures(json, projection as any);
      featuresObj.forEach((feature: Feature) => {
        source?.addFeature(feature);
      });
      setTimeout(() => {
        undoRedoPush();
      }, 0);
    }
  };

  return {
    addVectorSource,
    removeVectorLayer,
    addFeatureToSelect,
    changeVisibilityOverlays,
    addFeaturesToLayer
  };
};

export default useAddition;
