import { Checkbox, Button, SearchBar } from 'ui';
import { BASE_LAYER, renderSvg } from 'woodpecker';
import {
  button,
  layerContainer,
  layerContainerItem,
  layerItemIcons,
  layerItemName,
  modalButtons,
  modalContent,
  modalOverlay,
  modalWrapper,
  searchContainer,
  searchContainerCheckbox
} from './style';
import { ConfirmationModalProps } from './types';
import { useState } from 'react';

const ConfirmationModal = ({
  isOpen,
  message,
  layers,
  hiddenLayers,
  setHideLayers,
  onConfirm,
  onCancel
}: ConfirmationModalProps) => {
  const [search, setSearch] = useState<string>('');
  const [searchedLayers, setSearchedLayers] = useState<string[]>([]);

  if (!isOpen) return null;

  const handleSearch = (val: string) => {
    setSearch(val);
    const filteredLayers = [...layers.keys()].filter(layerId => {
      const layer = layers.get(layerId);
      return layer !== BASE_LAYER && layer.layer_type.toLowerCase().includes(val.toLowerCase());
    });
    setSearchedLayers(filteredLayers);
  };

  const handleToggleAllLayers = () => {
    const allLayerIds = search ? searchedLayers : [...layers.keys()];
    const layersToToggle = allLayerIds.filter(layerId => {
      const layer = layers.get(layerId);
      return layer !== BASE_LAYER && layer.layer_type.toLowerCase().includes(search.toLowerCase());
    });

    if (hiddenLayers.length >= layersToToggle.length) {
      setHideLayers([]);
    } else {
      setHideLayers(layersToToggle);
    }
  };

  const handleToggleLayer = (layerId: string) => {
    if (hiddenLayers.includes(layerId)) {
      setHideLayers(hiddenLayers.filter(l => l !== layerId));
    } else {
      setHideLayers([...hiddenLayers, layerId]);
    }
  };

  const allLayerIds = search ? searchedLayers : [...layers.keys()];
  const filteredLayers = allLayerIds.filter(layerId => {
    const layer = layers.get(layerId);
    return layer !== BASE_LAYER && layer.layer_type.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className={modalOverlay}>
      <div className={modalWrapper}>
        <div className={modalContent}>
          <p>{message}</p>
        </div>
        <div className={searchContainer}>
          {/* @ts-ignore */}
          <SearchBar
            placeholder='Search for layers (3 min chars)'
            onChangeFn={handleSearch}
            defaultValue=''
            debouncingTime={500}
            minChars={3}
            width={460}
          />
          <div className={searchContainerCheckbox}>
            <Checkbox checked={hiddenLayers.length >= filteredLayers.length} onChange={handleToggleAllLayers} />
            <div>Hide all {search ? 'searched ' : ''}layers</div>
          </div>
        </div>

        <div className={layerContainer}>
          {filteredLayers.map(layerId => {
            const layer = layers.get(layerId);
            if (layer === BASE_LAYER) return null;
            return (
              <div key={layerId} className={layerContainerItem}>
                <div className={layerItemIcons}>
                  <Checkbox checked={hiddenLayers.includes(layerId)} onChange={() => handleToggleLayer(layerId)} />
                  <div>{renderSvg[layer.geometry_type](layer.color)}</div>
                </div>
                <div className={layerItemName}>{layer.layer_type}</div>
              </div>
            );
          })}
        </div>
        <div className={modalContent}>
          <div className={modalButtons}>
            <Button onClick={onConfirm} className={button}>
              Confirm
            </Button>
            <Button onClick={onCancel} className={button}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
