import {
  GEOMETRY_TYPE,
  MEASUREMENT_TYPE_UNIT,
  MEASUREMENT_TYPE_UNIT_METRIC,
  METRIC_SYSTEM_ENUM,
  toSentenceCase
} from 'woodpecker';
import { useTags } from '../../../store/tagsStore';
import useMapStore, { TagsInfoType, TooltipDataType } from '../../../store/mapStore';
import { TOOL_TYPE } from '../../../js-layer/toolLayer/constants';

const RenderTagDetails = ({ tags }: { tags: TagsInfoType }) => {
  const { tagTypeMap = {}, tagMap = {}, subTagMap = {} } = useTags(state => state?.tagsMap || {});
  return Object.keys(tags)?.map(tagTypeId => {
    const { tagId, subtagId = null } = tags?.[tagTypeId] || {};
    const { name: tagTypeName } = tagTypeMap[tagTypeId] || {};
    const { name: tagName = null } = tagMap[tagId] || {};
    const { name: subtagName = null } = subtagId ? subTagMap[subtagId] : {};
    return (
      <div key={tagTypeId} className='section-row'>
        <span>{tagTypeName}</span>
        <span className='value'>
          {tagName}
          {subtagName ? <>&nbsp;&gt;&nbsp;{subtagName}</> : null}
        </span>
      </div>
    );
  });
};

const TooltipData = ({ data, index }: { data: TooltipDataType; index: number }) => {
  const { properties = [], tags_info = {}, default_tags = {} } = data || {};

  const selectedToolId = useMapStore(state => state.tool?.tool_id);
  const measurementSystem = useMapStore(state => state?.measurementSystem);
  const isMetric = measurementSystem === METRIC_SYSTEM_ENUM.METRIC;
  const measurementUnit = isMetric ? MEASUREMENT_TYPE_UNIT_METRIC : MEASUREMENT_TYPE_UNIT;

  return (
    <div
      className='menu-items'
      style={{
        borderLeft: `4px solid ${data?.color || 'black'}`
      }}>
      <div className='heading'>{data?.name} </div>
      {selectedToolId === TOOL_TYPE.PAN && (
        <>
          <hr />
          <div className='menu-item-section '>
            {properties.map(item => {
              let label = item.key.replace('_', ' ');
              label = label.replace('edit', 'edited');
              if (
                !label.includes('edited') &&
                data.geometry_type !== GEOMETRY_TYPE.POINT &&
                data.geometry_type !== GEOMETRY_TYPE.ABSTRACT &&
                data.geometry_type !== GEOMETRY_TYPE.TYPICAL
              ) {
                label = `actual ${label}`;
              }
              return (
                <div className='section-row' key={item + data?.name + data.geometry_type + index + Math.random() * 100}>
                  <span>{toSentenceCase(label)}</span>
                  <span className='value'>
                    {item.value} {measurementUnit[item.key] ? `${measurementUnit[item.key]}` : ''}
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}
      {[TOOL_TYPE.PAN, TOOL_TYPE.GROUP_TAG].includes(selectedToolId) && !!Object.keys(default_tags)?.length && (
        <>
          <hr />
          <div className='sub-heading'>Groups:</div>
          <div className='section-row sub-heading' style={{ color: '#808080' }}>
            <span>Group type</span>
            <span className='value'>Group&nbsp;&gt;&nbsp;Subgroup</span>
          </div>
          <div className='menu-item-section'>
            <RenderTagDetails tags={default_tags} />
          </div>
        </>
      )}
      {[TOOL_TYPE.PAN, TOOL_TYPE.TAG].includes(selectedToolId) && !!Object.keys(tags_info)?.length && (
        <>
          <hr />
          <div className='sub-heading'>Tag:</div>
          <div className='section-row sub-heading' style={{ color: '#808080' }}>
            <span>Tag type</span>
            <span className='value'>Tag&nbsp;&gt;&nbsp;Subtag</span>
          </div>

          <div className='menu-item-section'>
            <RenderTagDetails tags={tags_info} />
          </div>
        </>
      )}
    </div>
  );
};

export default TooltipData;
