import { asString } from 'ol/color';
import { Fill, Stroke, Style } from 'ol/style';
import MergeIcon from 'ui/src/Assets/MergeIcon';
import DeleteIcon from 'ui/src/Assets/DeleteIcon';
import MoveIcon from 'ui/src/Assets/MoveIcon';
import CopyAndMoveIcon from 'ui/src/Assets/CopyAndMoveIcon';
import DeleteRingIcon from 'ui/src/Assets/DeleteRingIcon';
import AssignFeatureIcon from 'ui/src/Assets/AssignFeatureIcon';
import SVGline from 'ui/src/Assets/SVGline';
import SVGpoint from 'ui/src/Assets/SVGpoint';
import SVGpolygon from 'ui/src/Assets/SVGpolygon';
import AbstractIcon from 'ui/src/Assets/AbstarctIcon';
import TypicalIcon from 'ui/src/Assets/TypicalIcon';

import RotateClockwiseIcon from 'ui/src/Assets/RotateClockwiseIcon';
import RotateAntiClockwiseIcon from 'ui/src/Assets/RotateAntiClockwiseIcon';
import FlipHorizontal from 'ui/src/Assets/FlipHorizontal';
import FlipVertical from 'ui/src/Assets/FlipVertical';
import { ToolBarItemsTypes } from '../types';

export type RightClickMenuItem = {
  title: string;
  tool_id: string;
};

export const MAP_TYPE = {
  AERIAL: 1,
  BLUEPRINT: 2
};

export const DEFAULT_ZOOM_VALUE = 24;
export const MIN_ZOOM_VALUE = 2;
export const SUPER_ZOOM_VALUE = 28;
export const GEOM_SIMPLIFICATION_TOLERANCE = 1e-16;
export const HIGHLIGHT_INVALIDTIES = 'HIGHLIGHT_INVALIDTIES';
export const CORRDINATES_LAYER = 'CORRDINATES_LAYER';

export const MAP_CONTAINER_ID = 'map-container';
export const ZOOM = 5;
export const GOOGLE_IMAGERY_SATELLITE = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';

export const BPT_PROJECTION = 'EPSG:40400';

export const DATA_PROJECTION = 'EPSG:4326';
export const FEATURE_PROJECTION = 'EPSG:3857';

export const GEO_JSON_OBJECT_PROJECTION = {
  dataProjection: DATA_PROJECTION,
  featureProjection: FEATURE_PROJECTION
};

export const GEOMETRY_TYPES_FEATURE = {
  POINT: 1,
  LINE: 2,
  POLYGON: 3,
  ABSTRACT: 4,
  TYPICAL: 5
};

export const EMPTY_GEOJSON = {
  type: 'FeatureCollection',
  features: []
};

export const IMAGE_BOUNDS_BASE_LAYER_TYPE = 2;
export const IMAGE_TILE_LAYER_TYPE = 1;

export const LAYER = {
  PARCEL: 'parcel_layer', // editable layer
  CANOPY: 'canopy_layer',
  LAWN: 'lawn_layer',
  BEDS: 'beds_layer',
  HEDGE: 'hedge_layer',
  SIDEWALK: 'sidewalk_layer',
  DRIVEWAY: 'driveway_layer',
  PAVEMENT: 'pavement_layer',
  PARKING_SPACE: 'parking_space_layer',
  DRIVELANE: 'drivelane_layer',
  DEBUG: 'debug_layer',
  TREE: 'tree_layer',
  CURB: 'curb_layer',
  PARKING_LOT: 'parking_lot_layer',
  LAWN_EDGE: 'lawn_edge_layer',
  DRIVABLE_AREA: 'drivable_area_layer',
  MULCH_BED: 'mulch_bed_layer',
  GRAVEL_BED: 'gravel_bed_layer',
  ROADWAYS: 'roadways_layer',
  BUILDING_FOOTPRINT: 'building_footprint_layer',
  MOWER_60: 'mower_60_layer',
  MOWER_48: 'mower_48_layer',
  MOWER_36: 'mower_36_layer',
  MOWER_21: 'mower_21_layer',
  TOTAL_TURF: 'total_turf_layer',
  PONDS: 'ponds_layer',
  ROUGH_MOW: 'rough_mow_layer',
  SECONDARY_MOW: 'secondary_mow_layer',
  DECIDUOUS_TREE: 'deciduous_tree_layer',
  PALM_TREE: 'palm_tree_layer',
  TREE_RINGS: 'tree_rings_layer',
  HEDGE_COUNT: 'hedge_point_layer',
  PUBLIC_SIDEWALK: 'public_sidewalk_layer',
  PRIVATE_SIDEWALK: 'private_sidewalk_layer',
  LOT_AREA: 'lot_area_layer',
  PLAYGROUND: 'playground_layer',
  WATERBODY: 'waterbody_layer',
  NATIVE_AREA: 'native_area_layer',
  SPORTS_FIELD: 'sports_field_layer',
  PRIMARY_MOW: 'primary_mow_layer',
  SHRUB_COUNT: 'shrubs_count_layer',
  LOADING_AREA: 'loading_area_layer',
  ASPHALT_CRACKS: 'asphalt_cracks_layer',
  CONCRETE_CRACKS: 'concrete_cracks_layer',
  GRAVEL_SURFACE: 'gravel_surface_layer',
  CURB_STRIPS: 'curb_strips_layer',
  FLOWER_BEDS: 'flower_beds_layer',
  ASHPALT_HARD_EDGES: 'asphalt_hard_edges_layer',
  CONCRETE_HARD_EDGES: 'concrete_hard_edges_layer',
  CHEMICAL_APPLICATION_BUFFER: 'chemical_application_buffer_layer',
  PARKING_GARAGE: 'parking_garage_layer',
  LINE_TRIM: 'line_trim_layer',
  TREE_WITH_RING: 'tree_with_ring_layer',
  TREE_WITHOUT_RING: 'tree_without_ring_layer',
  RETENTION_POND: 'retention_pond_layer',
  PARKING_STALLS_STRIPES: 'parking_stalls_stripes_layer',
  CROSS_HATCHING_STRIPES: 'cross_hatching_stripes_layer',
  STOP_BAR_STRIPES: 'stop_bar_stripes_layer',
  OTHER_STRIPES: 'other_stripes_layer',
  HANDICAP_PARKING_STALL_COUNT: 'handicap_parking_stall_count_layer',
  REGULAR_PARKING_STALL_COUNT: 'regular_parking_stall_count_layer',
  ASPHALT: 'asphalt_layer',
  CONCRETE: 'concrete_layer',
  CRACKS: 'cracks_layer',
  DIRECTIONAL_ARROWS_COUNT: 'directional_arrows_count_layer',
  GREEN: 'green_layer',
  FRINGE: 'fringe_layer',
  ROUGH: 'rough_layer',
  FAIRWAY: 'fairway_layer',
  TEE_BOX: 'tee_box_layer',
  CART_PATH: 'cart_path_layer',
  BUNKER: 'bunker_layer',
  TENNIS_COURT: 'tennis_court_layer',
  POTHOLE: 'pothole_layer'
};

export const LAYER_Z_INDEX = {
  [LAYER.BEDS]: 300,
  [LAYER.HEDGE]: 200,
  [LAYER.LAWN]: 150
};

export const MEASUREMENT_TYPE = {
  COUNT: 1,
  LENGTH: 2,
  AREA: 3,
  VOLUME: 4,
  WEIGHT: 5,
  WEIGHT_TON: 6,
  LUMP_SUM: 7,
  PERIMETER: 8
};

export const MEASUREMENT_TYPE_KEY = {
  [MEASUREMENT_TYPE.COUNT]: 'count',
  [MEASUREMENT_TYPE.LENGTH]: 'length',
  [MEASUREMENT_TYPE.AREA]: 'area',
  [MEASUREMENT_TYPE.VOLUME]: 'volume',
  [MEASUREMENT_TYPE.WEIGHT]: 'weight',
  [MEASUREMENT_TYPE.WEIGHT_TON]: 'weight_ton',
  [MEASUREMENT_TYPE.PERIMETER]: 'perimeter',
  [MEASUREMENT_TYPE.LUMP_SUM]: 'lump_sum'
};

export const MEASUREMENT_TYPE_NAME = {
  [MEASUREMENT_TYPE.COUNT]: 'Count',
  [MEASUREMENT_TYPE.LENGTH]: 'ft',
  [MEASUREMENT_TYPE.AREA]: 'sqft',
  [MEASUREMENT_TYPE.VOLUME]: 'yd³',
  [MEASUREMENT_TYPE.WEIGHT]: 'lb',
  [MEASUREMENT_TYPE.WEIGHT_TON]: 'tn',
  [MEASUREMENT_TYPE.PERIMETER]: 'ft',
  [MEASUREMENT_TYPE.LUMP_SUM]: 'LS'
};

export const MEASUREMENT_TYPE_NAME_METRIC = {
  [MEASUREMENT_TYPE.COUNT]: 'Count',
  [MEASUREMENT_TYPE.LENGTH]: 'm',
  [MEASUREMENT_TYPE.AREA]: 'm2',
  [MEASUREMENT_TYPE.VOLUME]: 'm³',
  [MEASUREMENT_TYPE.WEIGHT]: 'kg',
  [MEASUREMENT_TYPE.WEIGHT_TON]: 'tn',
  [MEASUREMENT_TYPE.PERIMETER]: 'm',
  [MEASUREMENT_TYPE.LUMP_SUM]: 'LS'
};

export const METRIC_SYSTEM_ENUM = {
  IMPERIAL: 1,
  METRIC: 2
};

export const MEASUREMENT_TYPE_UNIT = {
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.COUNT]]: '',
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.LENGTH]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.LENGTH],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.AREA]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.AREA],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.VOLUME]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.VOLUME],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.WEIGHT],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT_TON]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.WEIGHT_TON],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.PERIMETER]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.PERIMETER],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.VOLUME]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.VOLUME],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.WEIGHT],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT_TON]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.WEIGHT_TON],
  edit_area: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.AREA],
  edit_perimeter: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.LENGTH],
  edit_length: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.LENGTH],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.LUMP_SUM]]: MEASUREMENT_TYPE_NAME[MEASUREMENT_TYPE.LUMP_SUM]
};

export const MEASUREMENT_TYPE_UNIT_METRIC = {
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.COUNT]]: '',
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.LENGTH]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.LENGTH],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.AREA]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.AREA],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.VOLUME]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.VOLUME],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.WEIGHT],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT_TON]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.WEIGHT_TON],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.PERIMETER]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.PERIMETER],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.VOLUME]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.VOLUME],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.WEIGHT],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.WEIGHT_TON]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.WEIGHT_TON],
  edit_area: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.AREA],
  edit_perimeter: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.LENGTH],
  edit_length: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.LENGTH],
  [MEASUREMENT_TYPE_KEY[MEASUREMENT_TYPE.LUMP_SUM]]: MEASUREMENT_TYPE_NAME_METRIC[MEASUREMENT_TYPE.LUMP_SUM]
};

export const GEOMETRY_TYPES = {
  POINT: 'Point',
  LINESTRING: 'LineString',
  POLYGON: 'Polygon',
  MULTI_POLYGON: 'MultiPolygon',
  MULTI_LINESTRING: 'MultiLineString',
  MULTI_POINT: 'MultiPoint',
  CIRCLE: 'Circle'
};

export const REFERENCE_LAYER = 'Reference Layer';

export const GEOMETRY_TYPES_BY_INT = {
  1: GEOMETRY_TYPES.POINT,
  2: GEOMETRY_TYPES.LINESTRING,
  3: GEOMETRY_TYPES.POLYGON,
  4: GEOMETRY_TYPES.POINT,
  5: GEOMETRY_TYPES.POINT
};

export const GEOMETRY_ENUM = {
  [GEOMETRY_TYPES.POINT]: 1,
  [GEOMETRY_TYPES.LINESTRING]: 2,
  [GEOMETRY_TYPES.POLYGON]: 3
};

export const SELECT_MENU: RightClickMenuItem[] = [
  {
    title: 'Reshape',
    tool_id: 'add_reshape'
  }
];

export const MULTI_SELECT_MENU: RightClickMenuItem[] = [
  {
    title: 'Merge',
    tool_id: 'merge_feature'
  }
];

export function HighlightFeatureStyleFn() {
  const color = asString([255, 0, 0, 0.6]);
  return new Style({
    stroke: new Stroke({
      color,
      width: 3
    }),
    fill: new Fill({
      color
    })
  });
}

export const SLIVER_POLYGON_AREA_LIMIT = 0;
export const SLIVER_POLYGON_AREA_LIMIT_AERIAL = 0.3; // in square metres

export const UNDO_REDO_STACK_LIMIT = 25;

export const AbstractSvg = (
  fillColor: string,
  strokeColor?: string
) => `<svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 1.98732L15.9066 13.1683L15.9413 13.3018L16.0396 13.3987L24.2693 21.5063L13.133 18.433L13 18.3963L12.867 18.433L1.73069 21.5063L9.96041 13.3987L10.0587 13.3018L10.0934 13.1683L13 1.98732Z" fill="${fillColor}" stroke="${
  strokeColor || fillColor
}"  stroke-width="7%"/>
</svg>
`;

export const TypicalSvg = (fillColor: string, strokeColor?: string) => `<svg
  width="26"
  height="26"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10.0006 15.2167L4.12271 18.5069L5.43548 11.9L0.48999 7.32658L7.17919 6.53346L10.0006 0.416748L12.8219 6.53346L19.5111 7.32658L14.5657 11.9L15.8784 18.5069L10.0006 15.2167Z"
    fill="${fillColor}" stroke="${strokeColor || fillColor}" />
</svg>`;

export const GEOMETRY_TYPE: {
  [key: string]: number;
  POINT: number;
  LINE: number;
  POLYGON: number;
  ABSTRACT: number;
  TYPICAL: number;
} = {
  POINT: 1,
  LINE: 2,
  LINESTRING: 2,
  POLYGON: 3,
  ABSTRACT: 4,
  TYPICAL: 5
};

export const toSentenceCase = (str: string = '') => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word: string, index) => {
      if (index === 0) return word.charAt(0).toUpperCase() + word.slice(1);
      return word.toLowerCase();
    })
    .join(' ');
};

export const PX_TO_MM_CONSTANT = 25.4;

export const UNIT_CONVERSION_FACTORS = {
  SQ_METERS_TO_SQ_FEET: 10.764,
  METERS_TO_FEET: 3.281
};

export const blueprintShapelyScript = `
from shapely.geometry import Polygon, shape, mapping, LineString
from js import layers, scale

def meter_square_to_square_feet(value, precision):
    return round(value * 10.764, precision)

def meter_to_feet(value, precision):
    return round(value * 3.281, precision)

def actual_measurements(measurement_name, value, scale, dpi):
    px_to_mm = 25.4/dpi
    factor = px_to_mm * 0.001 * float(scale)
    if measurement_name == "area":
        value *= pow(factor,2)
    else:
        value *= factor
    return value

def get_coordinates_tuple(json_coordinates, geometry):
    coordinates_tuple_list = []
    if geometry == "Polygon":
        for coordinates in json_coordinates:
                for coordinate in coordinates:
                    tuple_list = (coordinate[0], coordinate[1])
                    coordinates_tuple_list.append(tuple_list)
    else:
        for coordinate in json_coordinates:
                tuple_list = (coordinate[0], coordinate[1])
                coordinates_tuple_list.append(tuple_list)
    return coordinates_tuple_list

def calculate_area_and_perimeter(coordinates_tuple_list, scale, area=False):
    ext = coordinates_tuple_list[0]
    int = coordinates_tuple_list[1:]
    polygonInteriors = []
    for i in range(len(int)):
        polygonInteriors.append(int[i])
    newPolygon = Polygon(ext, [[pt for pt in inner] for inner in polygonInteriors])
    if area:
        poly_area = actual_measurements("area", newPolygon.area, scale, 200)
        return meter_square_to_square_feet(poly_area, 2)
    else:
        poly_perimeter = actual_measurements("perimeter", newPolygon.length, scale, 200)
        return meter_to_feet(poly_perimeter, 2)

def get_geojson_feature(geometry, properties=None, feat_id='', convert_to_geojson=True):
    geojson_feat = {
        "type": "Feature",
    }
    if convert_to_geojson:
        geojson_feat["geometry"] = mapping(geometry)
    else:
        geojson_feat["geometry"] = geometry
    geojson_feat["properties"] = properties
    geojson_feat["id"] = feat_id

    return geojson_feat

def get_measurement_value(measurement_name, coordinates_tuple_list, scale):
    if measurement_name == "area":
        return calculate_area_and_perimeter(coordinates_tuple_list, scale, area=True)
    elif measurement_name == "perimeter":
        return calculate_area_and_perimeter(coordinates_tuple_list, scale)
    else:
        return calculate_length(coordinates_tuple_list, scale)

def get_geojson_feature_collection(geojson_features):
    feature_collection = {
        "type": "FeatureCollection",
        "features": [],
    }
    feature_collection["features"] = geojson_features
    return feature_collection

def calculate_length(coordinates_tuple_list, scale):
    line = LineString(coordinates_tuple_list)
    shape_perimeter_length =  actual_measurements("length", line.length, scale, 200)
    return meter_to_feet(shape_perimeter_length, 2)

def get_area_length(shape_geom, scale):
    if shape_geom["type"] == "Polygon" and shape_geom.get("coordinates"):
        coordinates_tuple_list = shape_geom.get("coordinates")
        measurement_value = get_measurement_value("area", coordinates_tuple_list, scale)

    elif shape_geom["type"] == "LineString" and shape_geom.get("coordinates"):
        coordinates_tuple_list = get_coordinates_tuple(shape.get("coordinates"), shape_geom["type"])
        measurement_value = get_measurement_value("length", coordinates_tuple_list, scale)
    return measurement_value

def calculate_metrics_construction(measurement_names, feat_geojson, scale, measurements, geometry_type, vector_layer_id = ""):
    features = feat_geojson.get("features")
    unit = {measurement.get("unit") for measurement in measurements}
    area_multiplier = 10.764 if "sqm" in unit else 1
    length_multiplier = 3.281 if "m" in unit else 1
    geojson_features = []

    for idx, feature in enumerate(features):
        json_geom = feature.get("geometry")
        feat_id = feature.get("id")
        feat_properties =  {"id": 0}
        if(feature.get("properties")):
           feat_properties = feature.get("properties")
        feat_properties["vector_layer_id"] = vector_layer_id

        # getting coordinates in tuple list format

        if json_geom["type"] == "Polygon" and json_geom.get("coordinates"):
            coordinates_tuple_list = json_geom.get("coordinates")
            properties = feat_properties
            properties["id"] = idx + 1
        elif json_geom["type"] == "LineString" and json_geom.get("coordinates"):
            coordinates_tuple_list = []
            coordinates_tuple_list = get_coordinates_tuple(json_geom.get("coordinates"), json_geom["type"])
            properties = feat_properties
            properties["id"] = idx + 1
        elif json_geom["type"] == "Point" and  json_geom.get("coordinates"):
            properties = feat_properties
            properties["id"] = idx + 1
        else:
            continue

        shape_geom = shape(json_geom)
        # For point feature, metric is not required to be calculated.
        if measurement_names and (geometry_type != 1 and geometry_type != 5):
            for measurement_name in measurement_names:
                measurement_name = measurement_name.lower()
                if geometry_type == 4:
                    measurement_value = get_abstract_feature_values(feature, measurement_name)
                else:
                    measurement_value = get_measurement_value(measurement_name, coordinates_tuple_list, scale)
                if measurement_name == "area":
                    measurement_value/=area_multiplier
                elif measurement_name in ["perimeter", "length"]:
                    measurement_value/=length_multiplier
                properties[measurement_name] = measurement_value

        if measurement_names and (geometry_type == 1 or geometry_type == 5):
           for measurement_name in measurement_names:
              if measurement_name in ["count"]:
                    measurement_value=1
              properties[measurement_name] = measurement_value

        geojson_geom = get_geojson_feature(shape_geom, properties, feat_id)
        geojson_features.append(geojson_geom)
    feature_collection = get_geojson_feature_collection(geojson_features)
    return feature_collection

def calculate_assemblies(output):
    noOfFeature = len(output["output_geojson"]["features"])
    for assembly in output["assemblies"]:
        assembly_value = float(assembly["value"]);
        assembly["measurement"] = round(assembly_value * noOfFeature, 2)


def create_measurements(outputs, scale):
    for output in outputs:
        geometry_type = output["feature"].get("geometry_type")
        if geometry_type in [1,5]:
            measurements_list = ["count"]
            measurements_list_edit = ["count", "edit_count"]
        elif geometry_type == 2:
            measurements_list = ["length"]
            measurements_list_edit = ["length", "edit_length"]
        elif geometry_type == 3:
            measurements_list = ["area", "perimeter"]
            measurements_list_edit = ["area", "perimeter", "edit_area", "edit_perimeter"]
        else:
            measurements_list = output["measurements"]
            measurements_list = {measurement.get("name") for measurement in output["measurements"]}
            measurements_list_edit = {measurement.get("name") for measurement in output["measurements"]}

        calculate_assemblies(output=output)
        geometry_type = output["feature"].get("geometry_type")
        vector_layer_id = output["feature"].get("feature_id")
        output["output_geojson"] = calculate_metrics_construction(measurements_list, output["output_geojson"], scale, output["measurements"], geometry_type, vector_layer_id)
        calculate_aggregate(output=output, geometry_type=geometry_type, measurements_list=measurements_list_edit)

def calculate_aggregate_metrics(measurement_names, feat_geojson):
    alternate_measurements_list = {
        "edit_count":"count",
        "edit_length": "length",
        "edit_area":"area",
        "edit_perimeter": "perimeter"
    }
    features = feat_geojson.get("features")
    agg_measurement = {}
    for measurement_name in measurement_names:
        agg_measurement[measurement_name.lower()] = 0

    for feature in features:
        properties = feature.get("properties")
        for measurement_name in measurement_names:
            measurement_name = measurement_name.lower()
            val = properties.get(measurement_name, properties.get(alternate_measurements_list.get(measurement_name), 0))
            agg_measurement[measurement_name] += val
    return agg_measurement

def get_multiplier(unit, name):
    if name == "area":
        return 10.764 if unit == "sq ft" else 1
    elif name == "length" or name == "perimeter":
        return 3.281 if unit == "ft" else 1
    else:
        return 1

def calculate_aggregate(output, geometry_type, measurements_list=None):
    """Calculate aggregate measurement metrics from individual feature metrics in output object's geojson.

    Args:
        output (apps.construction.models.ConstructionOutput): ConstructionOutput object for which aggregate metrics needs to
        be calculated
        geometry_type (int): geometry type of the output object
        measurements_list (list, optional): Measurement types for the output. Defaults to None.
    """
    output_geojson = output["output_geojson"]
    # Special handling for count as it is only an aggregate metric and not per feature metric
    agg_measurement_value_dict = calculate_aggregate_metrics(measurements_list, output_geojson)
    for measurement in measurements_list:
        agg_val = agg_measurement_value_dict.get(measurement.lower())
    measurements = output.get("measurements")

    for measurement in measurements:
        multiplier = 1
        measurement["value"]=agg_measurement_value_dict[measurement.get("name")]*multiplier

def get_abstract_feature_values(feature_json, measurement_name):
    geojson_properties = feature_json.get("properties")

    # Keeping default value for the feature as 0
    measurement_value = 0

    # If values are coming from the user, the values can also be in METRIC system.
    if measurement_name in geojson_properties.keys():
        measurement_value = float(geojson_properties.get(measurement_name, 0))
    return measurement_value

create_measurements(layers, scale)

layers
`;

export const GEOMETRY_Z_INDEX = {
  [GEOMETRY_TYPES_FEATURE.POINT]: 130,
  [GEOMETRY_TYPES_FEATURE.TYPICAL]: 100,
  [GEOMETRY_TYPES_FEATURE.ABSTRACT]: 120,
  [GEOMETRY_TYPES_FEATURE.LINE]: 110,
  [GEOMETRY_TYPES_FEATURE.POLYGON]: 100
};

export const DEFAULT_TOOL = {
  title: 'Pan',
  // icon: () => <PanIcon />,
  tool_id: 'pan',
  is_action: false,
  shortcut: 'p'
};

export interface Tool {
  icon?: (selected: boolean) => JSX.Element;
  title: string;
  tool_id: string;
  dropdownMenu?: Array<Tool>;
  tool_type?: number;
  geometry_available?: number[];
  is_action: boolean;
  snapping_mode?: boolean;
  edge_snapping?: boolean;
  feature_tracing?: boolean;
  snap_tolerance?: number;
  show_vertex?: boolean;
  avoid_overlap?: boolean;
  override_overlap?: boolean;
  clickId?: string;
  add_comment?: boolean;
  vector_layer_id?: string;
  reclassify_layer_id?: string;
  shortcut?: string;
  live_measurement?: boolean;
  external?: boolean;
  subTools?: Array<Tool>;
  lastTool?: any;
  show_vertices?: boolean;
  disabled_tabs?: Array<string>;
}

export interface SelectedTool extends Tool {
  clickId: string;
}

export const GEOMETRY_TYPES_VALUE = {
  [GEOMETRY_TYPES.POINT]: GEOMETRY_TYPES_FEATURE.POINT,
  [GEOMETRY_TYPES.MULTI_POINT]: GEOMETRY_TYPES_FEATURE.POINT,
  [GEOMETRY_TYPES.LINESTRING]: GEOMETRY_TYPES_FEATURE.LINE,
  [GEOMETRY_TYPES.MULTI_LINESTRING]: GEOMETRY_TYPES_FEATURE.LINE,
  [GEOMETRY_TYPES.POLYGON]: GEOMETRY_TYPES_FEATURE.POLYGON,
  [GEOMETRY_TYPES.MULTI_POLYGON]: GEOMETRY_TYPES_FEATURE.POLYGON,
  Abstract: GEOMETRY_TYPE.ABSTRACT,
  Typical: GEOMETRY_TYPE.TYPICAL
};

export const lengthScript = `
from shapely.geometry import Polygon, shape, mapping, LineString
from js import layers

def get_coordinates_tuple(json_coordinates, geometry):
    coordinates_tuple_list = []
    for coordinate in json_coordinates:
            tuple_list = (coordinate[0], coordinate[1])
            coordinates_tuple_list.append(tuple_list)
    return coordinates_tuple_list

def get_length_scale_tool(line_geojson):
    features = line_geojson.get("features")
    length = 0

    for idx, feature in enumerate(features):
        json_geom = feature.get("geometry")
        coordinates_tuple_list = get_coordinates_tuple(json_geom.get("coordinates"), json_geom.get("type"))
        line = LineString(coordinates_tuple_list)
        length+=line.length

    return length

len = get_length_scale_tool(layers)
length = {
    "len": len
}
length
`;

export const DIMENSION_TOOL_ID = 'line-geojson';
export const DIMENSION_TOOL_LAYER = 'line-geojson';
export const HIGHLIGHT_TOOL_LAYER = 'highlight-geojson';
export const HAND_TOOL_LAYER = 'handtool_geojson';
export const AUTO_SCALE_TOOL_LAYER = 'auto-scale-geojson';
export const AUTO_COUNT_TOOL_LAYER = 'auto-count-geojson';
export const AUTO_COUNT_TOOL_LAYER_NON_ACTIVE = 'auto-count-geojson_non_active';
export const COMMENT_LAYER_ID = 'comment-layer';
export const RENAME_TOOL_LAYER = 'rename-highlight-geojson';
export const SEARCH_TOOL_LAYER = 'search-highlight-geojson';
export const SEARCH_TOOL_LAYER_POINT = 'search-highlight-geojson-point';
export const HIGHLIGHT_LAYER_COLOR = '#fcc52b';
export const AUTO_SCALE_LAYER_COLOR = '#FFC166';
export const ARROW_TOOL = 'arrow_tool';

export const OVERLAY_LAYER_SET = new Set<string>([
  DIMENSION_TOOL_LAYER,
  HIGHLIGHT_TOOL_LAYER,
  HAND_TOOL_LAYER,
  ARROW_TOOL
]);

export const CLICK_TO_PANEL = {
  tool_id: 'click_to_panel',
  title: 'Click to panel'
};

export const MULTI_GEOMETRIES = [
  GEOMETRY_TYPES.MULTI_POLYGON,
  GEOMETRY_TYPES.MULTI_LINESTRING,
  GEOMETRY_TYPES.MULTI_POINT
];

export const BASE_LAYER = 'base_layer';
export const GOOGLE_LAYER = 'google_layer';
export const IMAGE_STATIC_ORTHO = 'image_static_ORTHO';
export const BUFFER_LAYER = 'buffer';

/**types */

export type Layer = {
  name?: string;
  layer_type?: string;
  original?: any;
  meta_data: { color: string; 'z-index': number };
  id: string | undefined;
  style?: any;
  data_status?: number;
  original_json: any;
  is_editable?: boolean;
  geometry_type?: number;
  measurement_type?: number;
  ml_rating: number;
  reason: string;
  qc_rating: number;
  default_tags: any;
  is_buffer: boolean;
  output_id?: string;
};

export type Feature = {
  type: string;
  geometry: {
    type: string;
    coordinates: number[][][][];
  };
  properties: null;
};

interface BoundaryLayerJson {
  type: string;
  features: Feature[];
}

interface ImageBounds {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface CurrentJob {
  id: string;
  base_layer: string;
  dsm_layer: any;
  base_layer_type: number;
  boundary_layer: any;
  boundary_layer_json: BoundaryLayerJson;
  image_bounds: ImageBounds;
  layers: Layer[];
  source_type: string;
  time_allotted_in_seconds: number;
  oblique_images: any[];
  meta_data: any;
  source_request_id: string;
  parcel_area: any;
  request_id: string;
  common_area_data: any;
  notes: any;
  tileserver_url: string;
  break_status: boolean;
  remaining_break_time: number;
  combine_all_features: boolean;
  time_left_in_seconds: number;
  time_extension_count: number;
  rating_handler_flag: boolean;
  comments: Array<any>;
  curation_step: number;
}
export type CurrentSheet = {
  id: string;
  index: number;
  image: string;
  width: number;
  height: number;
  status: number;
  sheetId: string;
  isWorkable: boolean;
  page_no: string;
  num: number;
  den: number;
  scale: number | null;
  dpi: number;
  scaleMultiplier: number;
  fileId: string;
  geojson_url?: string;
  name: string | null;
  client_file_id: string;
};
export const FEATURES_PROPERTIES = {
  [GEOMETRY_TYPE.POLYGON]: ['area', 'edit_area', 'perimeter', 'edit_perimeter'],
  [GEOMETRY_TYPE.LINE]: ['length', 'edit_length'],
  [GEOMETRY_TYPE.POINT]: ['count', 'edit_count'],
  [GEOMETRY_TYPE.ABSTRACT]: ['area', 'count', 'length', 'volume', 'weight', 'weight_ton', 'lump_sum'],
  [GEOMETRY_TYPE.TYPICAL]: ['count', 'edit_count']
};

export const UNSET_PROPERTIES: string[] = ['edit_area', 'edit_perimeter', 'edit_length'];

export const TOOLTIP_BAR_TYPE = {
  DRAW: 1,
  FEATURE: 2
};

export const TOOLTIP_BAR_ITEMS: ToolBarItemsTypes[] = [
  {
    title: 'Reclassify',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    geometry_available: [
      GEOMETRY_TYPE.POINT,
      GEOMETRY_TYPE.LINE,
      GEOMETRY_TYPE.POLYGON,
      GEOMETRY_TYPE.ABSTRACT,
      GEOMETRY_TYPE.TYPICAL
    ],
    icon: selected => <AssignFeatureIcon {...(selected ? { fill: '#4361EE' } : {})} />,
    tool_id: 'panel_click',
    multi: false,
    single: true,
    is_action: false
  },
  {
    title: 'Copy and Move',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    geometry_available: [
      GEOMETRY_TYPE.ABSTRACT,
      GEOMETRY_TYPE.POINT,
      GEOMETRY_TYPE.POLYGON,
      GEOMETRY_TYPE.LINE,
      GEOMETRY_TYPE.TYPICAL
    ],
    icon: selected => <CopyAndMoveIcon fill={selected ? '#4361EE' : '#666666'} />,
    tool_id: 'copy_and_move_feature',
    multi: true,
    single: false,
    excludedItems: true,
    is_action: false
  },
  {
    title: 'Move',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    geometry_available: [
      GEOMETRY_TYPE.ABSTRACT,
      GEOMETRY_TYPE.POINT,
      GEOMETRY_TYPE.POLYGON,
      GEOMETRY_TYPE.LINE,
      GEOMETRY_TYPE.TYPICAL
    ],
    icon: selected => <MoveIcon fill={selected ? '#4361EE' : '#666666'} />,
    tool_id: 'move_feature',
    multi: true,
    single: false,
    excludedItems: true,
    is_action: false
  },
  {
    title: 'Delete',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    geometry_available: [
      GEOMETRY_TYPE.ABSTRACT,
      GEOMETRY_TYPE.POINT,
      GEOMETRY_TYPE.POLYGON,
      GEOMETRY_TYPE.LINE,
      GEOMETRY_TYPE.TYPICAL
    ],
    icon: selected => <DeleteIcon fill={selected ? '#4361EE' : '#666666'} />,
    tool_id: 'delete',
    multi: true,
    single: false,
    excludedItems: true,
    is_action: true
  },
  {
    title: 'Merge',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    geometry_available: [GEOMETRY_TYPE.POLYGON],
    icon: selected => <MergeIcon fill={selected ? '#4361EE' : '#666666'} />,
    tool_id: 'merge',
    multi: false,
    single: true,
    is_action: true
  },
  {
    title: 'Delete ring',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    geometry_available: [GEOMETRY_TYPE.POLYGON],
    icon: selected => <DeleteRingIcon fill={selected ? '#4361EE' : '#666666'} />,
    tool_id: 'delete_ring',
    multi: false,
    single: false,
    is_action: false
  },
  {
    title: 'Rotate right',
    tool_id: 'rotate_clockwise',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    is_action: false,
    geometry_available: [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    icon: selected => <RotateClockwiseIcon fill={selected ? '#4361EE' : '#666666'} />
  },
  {
    title: 'Rotate left',
    tool_id: 'rotate_counter_clockwise',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    is_action: false,
    geometry_available: [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    icon: selected => <RotateAntiClockwiseIcon fill={selected ? '#4361EE' : '#666666'} />
  },
  {
    title: 'Flip horizontal',
    tool_id: 'flip_horizontal',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    is_action: false,
    geometry_available: [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    icon: selected => <FlipHorizontal fill={selected ? '#4361EE' : '#666666'} />
  },
  {
    title: 'Flip vertical',
    tool_id: 'flip_vertical',
    tool_type: TOOLTIP_BAR_TYPE.DRAW,
    is_action: false,
    geometry_available: [GEOMETRY_TYPE.LINE, GEOMETRY_TYPE.POLYGON],
    icon: selected => <FlipVertical fill={selected ? '#4361EE' : '#666666'} />
  }
];

export const renderSvg = {
  [GEOMETRY_TYPE.POINT]: (color: string) => <SVGpoint strokeColor={color} />,
  [GEOMETRY_TYPE.LINE]: (color: string) => <SVGline strokeColor={color} />,
  [GEOMETRY_TYPE.POLYGON]: (color: string) => <SVGpolygon strokeColor={color} />,
  [GEOMETRY_TYPE.ABSTRACT]: (color: string) => <AbstractIcon strokeColor={color} />,
  [GEOMETRY_TYPE.MULTI_LINE_STRING]: (color: string) => <SVGline strokeColor={color} />,
  [GEOMETRY_TYPE.TYPICAL]: (color: string) => <TypicalIcon strokeColor={color} />
};
//messages

export const ERROR_INVALID_FEATURES = 'Invalid features. Please delete and redraw highlighted features';
export const MULTIPOLYGON_ERROR = 'MultiPolygons detected in fms request';
export const ERROR_INVALID_PARCEL_FEATURES = 'Only polygon feature is allowed in parcel layer';

export const QC_STEP = 1;

export const GEOM_TYPE_STRINGS = {
  [GEOMETRY_TYPE.POLYGON]: 'Surface',
  [GEOMETRY_TYPE.LINE]: 'Edge',
  [GEOMETRY_TYPE.POINT]: 'Point',
  [GEOMETRY_TYPE.ABSTRACT]: 'Abstract',
  [GEOMETRY_TYPE.TYPICAL]: 'Typical'
};
export const COMMENT_STATUS = {
  PENDING: 1,
  RESOLVED: 2
};

export const COMMENT_LIMIT = 20;

export const RESOLVED_COMMENT_COLOR = '#C5C5C5';
export const LABEL_ACTIONS = {
  CREATED: 0,
  EDITED: 1,
  DELETED: 2,
  MOVED: 3
};

export const MARKUP_TYPE = {
  ARROW: 1,
  TEXT_BOX: 2
};

export const TOOL_SCOPE = {
  INTERNAL: 1,
  EXTERNAL: 2
};

export const ORTHO_ANGLE = 5;

export const CONVERSION_RATES_TO_METRIC = {
  ftToM: 1 / 3.28,
  sqFtToSqM: 1 / 10.8,
  cuYdToCuM: 1 / 1.308,
  lbToKg: 1 / 2.205,
  tonToMetricTon: 1 / 0.984207,
  gallonToLitre: 1 / 0.264,
  cuFtToCuM: 1 / 35.315,
  ydToM: 1 / 1.094,
  sqYdToSqM: 1 / 1.196,
  lbPerFtToKgPerM: 1 / 0.672
};

export const CONVERSION_RATES_TO_IMPERIAL = {
  mToFt: 3.28,
  sqMTosqFt: 10.8,
  cuMToCuYd: 1.308,
  kgToLb: 2.205,
  metricTonToTon: 0.984207,
  litreToGallon: 0.264,
  cuMToCuFt: 35.315,
  mToYd: 1.094,
  sqMToSqYd: 1.196,
  kgPerMToLbPerFt: 0.672
};

export const measurementConversions: any = {
  area: CONVERSION_RATES_TO_METRIC.sqFtToSqM,
  edit_area: CONVERSION_RATES_TO_METRIC.sqFtToSqM,
  length: CONVERSION_RATES_TO_METRIC.ftToM,
  edit_length: CONVERSION_RATES_TO_METRIC.ftToM,
  weight: CONVERSION_RATES_TO_METRIC.lbToKg,
  weight_ton: CONVERSION_RATES_TO_METRIC.lbToKg,
  perimeter: CONVERSION_RATES_TO_METRIC.ftToM,
  edit_perimeter: CONVERSION_RATES_TO_METRIC.ftToM
};

export const METRIC_CONVERSION_PRECISION = 5;

export const BUFFER_TYPE = {
  POSITIVE: 1,
  NEGATIVE: 2,
  BOTH: 3
};

export const BUFFER_UNITS = {
  FEET: 1,
  CENTI_METERS: 2
};
