import { ChromePicker } from 'react-color';
import { Popover } from 'ui';
import { colorDot } from './style';

type ColorPickerProps = {
  color: string;
  onColorChange: (color: string) => void;
};

const Picker = ({ color, onColorChange }: ColorPickerProps) => {
  const handleColorChange = (newColor: any) => onColorChange(newColor.hex);
  return (
    <div>
      <ChromePicker color={color} onChange={handleColorChange} disableAlpha={true} />
    </div>
  );
};

export const ColorPicker = ({ color, onColorChange }: ColorPickerProps) => {
  return (
    <Popover triggerEvent='click' content={<Picker color={color} onColorChange={onColorChange} />}>
      <div className={colorDot} style={{ background: color }} />
    </Popover>
  );
};
