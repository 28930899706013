import useTextBoxStore from '../../../store/textBoxStore';

class TextBoxStoreListener {
  public TextStore: any = null;

  private static instance: TextBoxStoreListener;

  private constructor() {
    useTextBoxStore.subscribe(state => this.handleStoreChange(state));
    this.TextStore = useTextBoxStore?.getState();
  }

  handleStoreChange(state: any) {
    this.TextStore = state;
  }

  static getInstance() {
    if (!TextBoxStoreListener.instance) {
      TextBoxStoreListener.instance = new TextBoxStoreListener();
    }
    return TextBoxStoreListener.instance;
  }
}

export const globalTextBoxStore = TextBoxStoreListener.getInstance();

export default TextBoxStoreListener;
