import React from "react";

export const CircleLineIcon = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#4D4D4D"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6024 1.6665 18.3334 5.39746 18.3334 9.99984C18.3334 14.6022 14.6024 18.3332 10.0001 18.3332ZM10.0001 16.6665C13.682 16.6665 16.6667 13.6818 16.6667 9.99984C16.6667 6.31794 13.682 3.33317 10.0001 3.33317C6.31818 3.33317 3.33341 6.31794 3.33341 9.99984C3.33341 13.6818 6.31818 16.6665 10.0001 16.6665Z"
        fill="inherit"
      />
    </svg>
  );
};
