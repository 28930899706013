import React from "react";

const SimpleDeleteIcon = ({ fill = "#4D4D4D", title = "", ...props }) => {
  return (
    <svg
      width="1.063rem"
      height="1.125rem"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <path
        d="M13.1665 3.50033H17.3332V5.16699H15.6665V16.0003C15.6665 16.2213 15.5787 16.4333 15.4224 16.5896C15.2661 16.7459 15.0542 16.8337 14.8332 16.8337H3.1665C2.94549 16.8337 2.73353 16.7459 2.57725 16.5896C2.42097 16.4333 2.33317 16.2213 2.33317 16.0003V5.16699H0.666504V3.50033H4.83317V1.00033C4.83317 0.779312 4.92097 0.56735 5.07725 0.41107C5.23353 0.25479 5.44549 0.166992 5.6665 0.166992H12.3332C12.5542 0.166992 12.7661 0.25479 12.9224 0.41107C13.0787 0.56735 13.1665 0.779312 13.1665 1.00033V3.50033ZM13.9998 5.16699H3.99984V15.167H13.9998V5.16699ZM6.49984 1.83366V3.50033H11.4998V1.83366H6.49984Z"
        fill={fill}
      />
    </svg>
  );
};

export default SimpleDeleteIcon;
