/* eslint-disable react/jsx-no-useless-fragment */
import { StyleProvider, Toast } from 'ui';
import Map, { MapsProps } from '.';
import '../js-layer/mapLayer/openlayerPolyfills';

const MapsWithTheme = ({ enableToast = true, ...props }: MapsProps & { enableToast?: boolean }) => {
  return (
    <StyleProvider>
      {enableToast ? (
        <Toast
          newestOnTop={false}
          limit={1}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          hideProgressBar
        />
      ) : (
        <></>
      )}
      <Map {...props} />
    </StyleProvider>
  );
};

export default MapsWithTheme;
